@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.general-information {
  width: 100%;
  height: 100%;
  margin-top: 2rem;
  @include flex-row;

  &__wrapper {
    @include column;

    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 0rem 1.2rem 0 1.2rem;

    & > div {
      align-self: flex-end;
    }
  }

  &--btn {
    &__trigger {
      width: 100%;
      height: 46px;
      background-color: $col-primary;
      padding: 0px 24px;
      box-shadow: inset 0px -6px 10px #314f4a;
      @include flex-row;
      @include border-radius(4px);
      @include transition;

      & span {
        color: $col-white !important;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.375rem;
      }

      &:focus {
        outline: 1px dotted $col-secondary-8;
        outline-offset: 1px;

        & span {
          color: $col-white;
        }
      }

      &:disabled,
      &[disabled] {
        @include border-radius(4px);
        background-color: $col-secondary-8;
        box-shadow: none;
        filter: none;

        &:hover,
        &:focus-within {
          background-color: $col-secondary-8;
          cursor: not-allowed;

          & span {
            color: $col-white;
          }
        }
      }

      &:hover,
      &:focus-within {
        background-color: $col-secondary-4;

        & span {
          color: $col-white;
        }
      }

      &:active {
        background-color: $col-primary;
      }
    }
  }

  &--box {
    @include column;

    justify-content: flex-start;
    align-items: center;
    gap: 1.2rem;
    border: 1px solid #cccccc;
    border-radius: 8px;
    padding: 40px;
    width: 100%;
    max-width: 600px;

    h1 {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      text-align: center;
    }

    &__msg {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #000000;
    }

    &__add {
      padding: 12px 16px;
      background: #f9fbfb;
      border: 1px dashed #34544f;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #34544f;

      @include flex-row;
      gap: 0.5rem;
      flex-wrap: wrap;

      &__trigger {
        background: #ffffff;
        padding: 8px 16px;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: $col-black;
        border: 1px solid #bfbfbf;
        border-radius: 4px;
      }
    }
  }

  &--hashtag {
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: $col-black;
  }

  &--slug {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3b5f59;
    cursor: pointer;

    @include flex-row;
    gap: 0.25rem;
  }
}
