@use "../../../../styles/abstracts/mixins" as *;
@use "../../../../styles/abstracts/variables" as *;

.backstory {
  &__trigger {
    width: 100%;
    height: 46px;
    background-color: $col-primary;
    padding: 0px 24px;
    box-shadow: inset 0px -6px 10px #314f4a;
    @include flex-row;
    @include border-radius(4px);
    @include transition;

    & span {
      color: $col-white !important;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.375rem;
    }

    &:focus {
      outline: 1px dotted transparent;
      outline-offset: 1px;

      & span {
        color: $col-white;
      }
    }

    &:focus-visible {
      outline: 1px dotted $col-secondary-8;
      outline-offset: 1px;

      & span {
        color: $col-white;
      }
    }

    &:disabled,
    &[disabled] {
      @include border-radius(4px);
      background-color: $col-secondary-8;
      box-shadow: none;
      filter: none;

      &:hover,
      &:focus-within {
        background-color: $col-secondary-8;
        cursor: not-allowed;

        & span {
          color: $col-white;
        }
      }
    }

    &:hover,
    &:focus-within {
      background-color: $col-secondary-4;

      & span {
        color: $col-white;
      }
    }

    &:active {
      background-color: $col-primary;
    }
  }

  &__card {
    box-shadow: 0px 1px 1px rgba(224, 224, 224, 0.25);
    background-color: $col-white;
    border: 1px solid $col-secondary;
    width: 100%;
    max-width: 300px;
    padding: 24px;
    @include column;
    justify-content: center;
    margin: 0 auto;

    &-top {
      &-container {
        width: 100%;
        height: 280px;
        position: relative;
        margin: 0rem auto 1rem;
        background-color: #bfbfbf;
        border-radius: 4px;
        overflow: hidden;

        img {
          object-fit: cover;
          object-position: center;
          filter: blur(0) !important;
        }
      }
    }

    &-title {
      &-container {
        margin-bottom: 1rem;
        @include flex-space-between;
        width: 100%;
      }
      & h4 {
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.5625rem;
        text-align: left;
      }
    }
  }

  &__button {
    background-color: #fff;
    border: 1px solid #bfbfbf;
    border-radius: 4px;
    height: 43px;
    width: 100%;
    margin-top: 24px;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    color: $col-black;
    line-height: 1.1875rem;
    text-align: center;
  }

  &__message {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    text-align: left;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    & p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  &__dropdown {
    @include floating-transition;
    padding: 0.5rem;

    &-container {
      position: relative;
      &-button {
        background-color: $col-white;
        @include border-radius(4px);
        @include row;
        width: 2rem;
        height: 2rem;
        justify-content: center;
        align-items: center;
        color: $col-black;
        font-weight: 600;
        font-style: normal;
        font-size: 0.875rem;
        line-height: 1.1875rem;

        &:hover,
        &:focus,
        &:focus-visible {
          background-color: $col-secondary;
        }
      }
    }

    &-list {
      display: grid;

      & button {
        text-align: left;
        padding: 0.5rem 1rem !important;
        font-size: 1rem;
        color: $col-black;
        border-radius: 4px !important;

        &:hover,
        &:focus {
          background-color: #eff5f4 !important;
        }
      }

      &__remove {
        color: rgb(247, 49, 49);
      }
    }

    @include z-index("dropdown-menu");
    background-color: $col-white;
    box-shadow: 1px 2px 6px rgba(51, 51, 51, 0.15);
    @include border-radius(4px);
    width: fit-content;
    white-space: nowrap;
    height: auto;
    margin-top: 10px;
    &[aria-expanded="true"] {
      display: block;
    }
  }

  &__remove {
    color: rgb(247, 49, 49);
  }
}
