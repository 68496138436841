@use "../../../../styles/abstracts/mixins" as *;
@use "../../../../styles/abstracts/variables" as *;

.wishlist__card {
  background: $col-secondary-2;
  border-radius: 4px;

  &__block {
    width: 100%;

    .card {
      &-description {
        color: $col-black;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 16px;
        gap: 20px;

        h5 {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          text-transform: capitalize;
          width: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        p {
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
        }
      }

      &-dropdown-container {
        position: absolute;
        top: 1rem;
        right: 1rem;
      }
    }
  }

  &__row {
    min-width: 100%;

    .card {
      &-description {
        color: $col-black;
        display: flex;
        align-items: center;
        padding: 16px 40px;
        gap: 16px;
        justify-content: space-between;
        width: 100%;

        @include respond(tab) {
          justify-content: flex-start;
          width: fit-content;
          padding: 16px;
        }

        h5 {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          text-transform: capitalize;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow-x: hidden;
          text-align: left;
          width: 100px;

          @include respond(tab) {
            width: 180px;
          }

          &:focus {
            outline: 1px dotted transparent;
          }

          &:focus-visible {
            outline: 1px dotted $col-black;
          }
        }

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow-x: hidden;
          text-align: left;
          width: 100px;

          @include respond(tab) {
            width: 180px;
          }

          &:focus {
            outline: 1px dotted transparent;
          }

          &:focus-visible {
            outline: 1px dotted $col-black;
          }
        }

        &-dropdown-container {
          position: relative;
        }
      }
    }
  }
}

.card {
  &-dropdown {
    &-button {
      background-color: $col-secondary-2;
      @include border-radius(4px);
      @include row;
      width: 2rem;
      height: 2rem;
      justify-content: center;
      align-items: center;
      color: $col-black;

      &:hover,
      &:focus,
      &:focus-visible {
        background-color: $col-secondary;
      }
    }

    &-list {
      padding: 0.5rem;
      background: $col-white;
      box-shadow: 0px 0px 2px rgba(125, 125, 125, 0.2),
        0px 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      margin-top: 0.5rem;

      @include floating-transition;

      &-edit {
        padding: 0.5rem 1rem;
        cursor: pointer;
        @include transition;
        border-radius: 4px;
        &:hover,
        &:focus {
          background-color: $col-secondary-32;
        }
      }

      &-delete {
        padding: 0.5rem 1rem;
        cursor: pointer;
        color: #e61919;
        border-radius: 4px;
        @include transition;

        &:hover,
        &:focus {
          background-color: $col-secondary-32;
        }
      }
    }
  }
}
