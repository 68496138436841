@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.event-details {
  height: 70vh;
  position: relative;
  padding: 0 30px;

  @include respond(phone) {
    padding: 0;
  }

  &__header {
    @include flex-row-custom(flex-start);
    border-bottom: 1px solid $col-secondary-7;
    overflow: hidden;
    padding: 0rem 1.2rem 0 1.2rem;

    p {
      height: 100%;
      padding: 0rem 1rem 0.5rem 1rem;
      cursor: pointer;
      @include transition;
      margin-bottom: -2px;
      display: block;

      &[data-active="true"] {
        border-bottom: 3px solid $col-secondary-4;
        box-shadow: inset 0px -3px 0px $col-secondary-4;
        padding: 10px 10px;
      }
    }
  }

  &__main {
    margin-top: 2rem;
    width: 100%;
    height: 100%;
  }

  &__tab-container {
    border-bottom: 1px solid $col-secondary-7;
    width: 100%;
    @include row;
    align-items: center;
    padding: 1.2rem 1.2rem 0;
  }
}
