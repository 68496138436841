@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.removePlannerModal {
  &--content {
    background-color: $col-white;
    padding: 40px;
    height: auto;
    width: 551px;
    display: grid;
    grid-template-rows: 60px 1fr;
    grid-row-gap: 20px;
    border-radius: 5px;
    height: fit-content;

    @include respond(phone) {
      width: 100vw;
      min-height: 100vh;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: fixed;
      @include z-index(fill-page);
    }

    h1 {
      margin-bottom: 10px;
    }
    p {
      font-size: 16px;
    }

    &-btnContainer {
      display: flex;
      width: auto;
      margin-top: 0.4rem;
      div {
        margin-left: auto;
        button {
          width: auto;
        }
      }
    }
  }
}
