@use "src/styles/abstracts/variables" as *;
@use "src/styles/abstracts/mixins" as *;

.uploadCard {
  background-color: $col-white;
  padding: 40px 40px 10px 40px;
  border-radius: 5px;
  height: 418px;
  width: 551px;
  display: grid;
  grid-template-rows: 40px 1fr;
  grid-row-gap: 40px;

  @include respond(phone) {
    max-height: unset;
    height: 100vh;
    height: -moz-available;
    height: -webkit-fill-available;
    height: calc(100vh - calc(100vh - 100%));
    width: 100vw;
    width: -moz-available;
    width: -webkit-fill-available;
    border-radius: 0px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    @include z-index(fill-page);
  }

  &-heading {
    width: 100%;

    @include respond(phone) {
      width: 80vw;
    }

    & h4 {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      text-align: left;
      color: $col-black;
      white-space: nowrap;
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-form {
    &-container {
      @include grid-row(auto-fill, auto);
      grid-row-gap: 24px;
    }
  }

  &-image {
    @include grid-row(2, auto);
    grid-row-gap: 8px;

    &-box {
      width: 100%;
      height: 200px;
      background: #fafafa;
      border: 1px solid #f2f2f2;
      border-radius: 5px;
      position: relative;
      overflow-y: hidden;

      @include flex-row;
      & div {
        width: 70px;
        height: 70px;
      }

      &-container {
        & p {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: #000000;
          margin-bottom: 8px;
        }
      }
    }
  }

  &-file-picker {
    &-text {
      margin-bottom: 8px;
    }
    &-box {
      width: fit-content;
      height: 46px;
      padding: 0px 24px;
      @include border-radius(4px);
      background-color: #eff5f4;
      @include flex-row;
    }
  }
  &-button {
    width: 165px;
    &-container {
      width: 100%;
      @include flex-space-between;
      justify-content: flex-end;
      height: 70px;
    }
  }
}

.file {
  // width: 0.1px;
  // height: 0.1px;
  // opacity: 0;
  // overflow: hidden;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  // z-index: -1;
  width: 100%;
  height: 100%;
  background: rgba(150, 225, 150, 0.5);
  vertical-align: middle;
  text-align: center;
  padding: 0 20%;
  color: #fff;
  font-weight: bold;
  font-size: 400px;
  opacity: 0;
  cursor: pointer;

  & + label {
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #bfbfbf;
  }
}

.file + label {
  cursor: pointer;
}
