@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.confirmTransfer {
  &__title {
    @include grid-row(2, auto);
    grid-row-gap: 2px;

    & h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      height: 40px;
      margin-bottom: 10px;
    }

    & p {
      padding-top: 0.5rem;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      color: $col-black;

      @include respond(big-tab) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &__main {
    display: grid;
    width: 100%;
    height: 100%;

    &-container {
      grid-column: 2/3;
      background-color: $col-white;
      justify-content: flex-start;
      height: 100%;
      padding: 40px;
      gap: 5px;
      border-radius: 5px;

      @include column;

      &-inner {
        @include grid-row(2, auto);
        grid-row-gap: 5px;
      }

      @include respond(phone) {
        height: 100vh;
        height: -moz-available;
        height: -webkit-fill-available;
        height: calc(100vh - calc(100vh - 100%));
        width: 100vw;
        height: calc(100vh - calc(100vh - 100%));
        width: -moz-available;
        width: -webkit-fill-available;
        @include column;
        gap: 0.75rem;
        @include z-index(fill-page);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: fixed;
      }
    }
  }

  &__text {
    margin-top: 40px;
    font-size: 1rem;
    line-height: 1.375rem;
    font-style: normal;
    font-weight: normal;
    color: $col-black;
  }

  &__button {
    display: flex;
    gap: 0.5rem;
    margin-top: 40px;
    margin-bottom: 13px;

    @include respond(tab) {
      flex-direction: column;
    }
  }
  &__details {
    display: flex;
    gap: 3.5rem;
    // justify-content: space-between;

    &-receiver {
      background-color: #fafafa;
      display: flex;
      gap: 1.5rem;
      justify-content: space-between;
      padding: 1rem 1.5rem;
      width: 30rem;
      overflow: hidden;

      @include respond(phone) {
        text-align: end;
        width: auto;
      }
    }

    &__name {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: right;
    }

    &__line {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: right;
    }

    &-account {
      background-color: #f4f4f5;
      display: flex;
      gap: 3.5rem;
      justify-content: space-between;
      padding: 1rem 1.5rem;
      width: 30rem;
      overflow: hidden;

      &__title {
        display: flex;
        align-items: center;
        gap: 6px;
      }

      @include respond(phone) {
        width: auto;
        text-align: start;
      }
    }

    &-bank {
      background-color: #fafafa;
      display: flex;
      gap: 5rem;
      justify-content: space-between;
      padding: 1rem 1.5rem;
      overflow: hidden;
      width: 30rem;

      @include respond(phone) {
        width: 100%;
        text-align: end;
      }
    }
  }

  &__field {
    @include grid-column(5, 60px);
    grid-column-gap: 8px;
    margin: 0 auto;
    place-items: center;
    width: fit-content;

    @include respond(phone) {
      @include grid-column(5, 1fr);
    }

    &-input {
      background-color: $col-white;
      border: 1px solid $col-secondary-9;
      width: 100%;
      height: 78px;

      @include border-radius(2px);

      font-size: 2.25rem;
      line-height: 3.0625rem;
      font-style: normal;
      font-weight: normal;
      color: $col-black;
      text-align: center;

      &:focus {
        border: 1px solid $col-secondary-10;
        caret-color: $col-secondary-10;
      }
    }
  }
}
