@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.notFound {
  height: 100vh;
  background-color: $col-white;
  @include flex-row;

  &__icon {
    width: 100%;
    @include flex-row;
  }

  &__container {
    @include grid-row(4, auto);
    grid-row-gap: 16px;
    place-items: center;
    padding: 20px;
  }

  &__title {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.0625rem;
    letter-spacing: 0em;
    color: $col-black;
  }

  &__description {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0em;
    text-align: center;
  }

  &__button {
    width: 100%;
    @include flex-row;

    & button {
      width: fit-content;
    }
  }
}
