@use "../../../../styles/abstracts/variables" as *;
@use "../../../../styles/abstracts/mixins" as *;

.view {
  background-color: $col-white;
  padding: 40px;
  height: auto;
  width: 551px;
  display: grid;
  // grid-template-rows: 60px 1fr;
  grid-row-gap: 40px;
  border-radius: 5px;

  @include respond(phone) {
    border-radius: 0px;
    height: 100vh;
    height: -moz-available;
    height: -webkit-fill-available;
    height: calc(100vh - calc(100vh - 100%));
    width: 100vw;
    width: -moz-available;
    width: -webkit-fill-available;
    @include z-index(fill-page);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &-heading {
    & h4 {
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: 2.0625rem;
      text-align: left;
    }
  }

  &-button {
    width: fit-content;
    justify-self: flex-end;
  }

  &-body {
    height: 100%;
    overflow-y: scroll;
  }

  &-trigger {
    width: 100%;
    height: 46px;
    background-color: $col-primary;
    padding: 0px 24px;
    box-shadow: inset 0px -6px 10px #314f4a;
    @include flex-row;
    @include border-radius(4px);
    @include transition;

    & span {
      color: $col-white !important;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.375rem;
    }

    &:focus {
      outline: 1px dotted transparent;
      outline-offset: 1px;

      & span {
        color: $col-white;
      }
    }

    &:focus-visible {
      outline: 1px dotted $col-secondary-8;
      outline-offset: 1px;

      & span {
        color: $col-white;
      }
    }

    &:disabled,
    &[disabled] {
      @include border-radius(4px);
      background-color: $col-secondary-8;
      box-shadow: none;
      filter: none;

      &:hover,
      &:focus-within {
        background-color: $col-secondary-8;
        cursor: not-allowed;

        & span {
          color: $col-white;
        }
      }
    }

    &:hover,
    &:focus-within {
      background-color: $col-secondary-4;

      & span {
        color: $col-white;
      }
    }

    &:active {
      background-color: $col-primary;
    }
  }

  &__scroll {
    width: 100%;
    height: 400px;
    overflow: hidden;

    @include respond(phone) {
      height: 70vh;
    }

    &__bar {
      width: 8px;
      background: #fff8e6;
      border-radius: 30px;
      height: 100%;
    }

    &__thumb {
      width: 8px;
      height: 26px;
      background: #ffe299;
      border-radius: 30px;
    }
  }

  &__bottom {
    @include column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }
}
