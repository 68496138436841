@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.dialog {
  &__overlay {
    position: fixed;
    inset: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2147483646;
    overflow-y: auto;
    gap: 0.75rem;

    // @include flex-column;
    @include overlay-animation;

    @include respond(phone) {
      background: #fff;
      height: calc(100vh - calc(100vh - 100%));
    }
  }

  &__content {
    align-items: flex-end !important;
    width: fit-content;
    overflow-y: auto;
    margin: 0 auto;
    min-height: 100vh;

    @include flex-column;

    z-index: 2147483647;

    // @include overlay-content-show;

    &:focus {
      outline: none;
    }

    @include respond(tab) {
      align-items: center !important;
    }

    @include respond(phone) {
      align-items: center !important;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100vh;
      height: -moz-available;
      height: -webkit-fill-available;
      height: calc(100vh - calc(100vh - 100%));
      width: 100vw;
      // width: -moz-available;
      // width: -webkit-fill-available;
    }
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: flex-end;
    align-self: flex-end;
    height: 40px;
    width: 40px;
    border: 1px solid transparent;
    border-radius: 50%;
    background-color: $col-white;
    margin-bottom: 1rem;

    @include respond(tab) {
      position: absolute;
      z-index: 1001;
      top: 0.75rem;
      right: 0.75rem;
    }

    @include respond(phone) {
      position: absolute;
      z-index: 1001;
      top: 2.6rem;
      transform: translateY(-12.5%);
      right: 2rem;
    }
  }

  &__trigger {
    // border: 1px solid transparent !important;
    border-radius: 0 !important;
    padding: 0 !important;
    background: transparent !important;
    width: auto;

    span {
      color: initial;
    }
  }

  // &__trigger:focus {
  //   border: 1px dotted transparent !important;
  // }

  &__trigger:focus-visible {
    border: 1px dotted $col-black !important;
  }
}
