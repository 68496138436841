@use "src/styles/abstracts/variables" as *;
@use "src/styles/abstracts/mixins" as *;

.shareInviteCard {
  background-color: $col-white;
  padding: 40px 40px 10px 40px;
  border-radius: 5px;
  height: auto;
  width: 591px;
  max-height: 90vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  @include respond(phone) {
    max-height: unset;
    height: 100vh;
    height: -moz-available;
    height: -webkit-fill-available;
    height: calc(100vh - calc(100vh - 100%));
    width: 100vw;
    width: -moz-available;
    width: -webkit-fill-available;
    border-radius: 0px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    @include z-index(fill-page);
  }

  &__heading {
    width: 100%;
    margin-bottom: 0.5rem;

    @include respond(phone) {
      width: 80vw;
    }

    & h4 {
      font-weight: 500;
      font-size: 25px;
      line-height: 30px;
      text-align: left;
      color: $col-black;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__subHeading {
    width: 100%;

    @include respond(phone) {
      width: 80vw;
    }

    & span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      color: $col-secondary-9;
    }
  }

  &__invitationCon {
    display: grid;
    place-items: center;
    margin: 1.5rem 0;
    max-height: 50vh;
    overflow-y: auto;
    scrollbar-width: 0;

    &::-webkit-scrollbar {
      display: none;
    }

    &--isPreview {
      height: 50vh;
      overflow-y: auto;
      display: grid;
      place-items: center;
    }

    img {
      width: 250px;
      @include respond(phone) {
        width: 100%;
        height: auto;
      }
    }
  }

  &__linksCon {
    width: 100%;
    border-top: 1px solid $col-secondary-43;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 1rem;

    &__buttons {
      cursor: pointer;
      height: 48px;
      width: 122px;
      display: flex;
      gap: 0.5rem;
      background-color: $col-white;
      padding: 0px 1rem;
      border: 1px solid $col-secondary-43;
      box-sizing: border-box;

      @include flex-row;
      @include border-radius(4px);
      @include transition;

      color: $col-secondary-13;
      font-style: normal;
      font-family: $font;
      font-weight: 500;
      font-size: 14px;
      white-space: nowrap;
      background-color: $col-white !important;

      &:focus-visible {
        outline: 1px dotted $col-black;
        outline-offset: 1px;
      }

      &:hover,
      &:focus,
      &:active {
        color: $col-black;
        border: 1px solid $col-secondary-9;
        background-color: transparent !important;
      }
    }
  }
}
