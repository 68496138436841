@use "../../../../styles/abstracts/variables" as *;
@use "../../../../styles/abstracts/mixins" as *;

.table {
  &__btn {
    width: 100%;
    height: 100%;
    @include flex-row;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
    padding: 8px 16px;
    @include border-radius(3px);
    color: #141414;
    border: 1px solid #141414;
  }

  &__list-item {
    @include flex-row;

    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0.5rem;
    width: 100%;
    padding: 24px 40px;
    background: #fafafa;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #25213b;

    @include respond(tab) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &__left {
      display: inline;
    }
  }
  &__name {
    text-transform: capitalize;
  }
  &__price {
    width: 100%;
  }
  &__amount {
    font-weight: 700;
  }
  &__action {
    width: 100%;

    button {
      border: 1px solid #141414;
      box-sizing: border-box;
      border-radius: 3px;
      color: #141414;
      white-space: nowrap;
      padding: 10px 20px;
      height: auto;

      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
      }
    }
  }
}
