@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.viewTask {
  &--content {
    background-color: $col-white;
    padding: 40px;
    height: auto;
    width: 551px;
    @include column;
    gap: 20px;
    border-radius: 5px;
    height: fit-content;
    max-height: 85vh;
    overflow-y: auto;

    @include respond(phone) {
      max-height: unset;
      height: 100vh;
      height: -moz-available;
      height: -webkit-fill-available;
      height: calc(100vh - calc(100vh - 100%));
      width: 100vw;
      width: -moz-available;
      width: -webkit-fill-available;
      border-radius: 0px;

      @include z-index(fill-page);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: fixed;
    }

    &--description {
      padding: 0px 0;
    }

    &-btnContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;

      @include respond(phone) {
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
      }

      div {
        margin-left: auto;
        button {
          width: auto;
        }
      }
    }
  }

  &--date {
    font-weight: 400;
    font-size: 14px;
    color: #71717a;
  }
  &--title {
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    color: #141414;
  }
  &--edit {
    border: 1px solid #e4e4e7;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &--button {
    border: 1px solid #e4e4e7;
    border-radius: 4px;
    padding: 7px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &--row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    @include respond(tab) {
      justify-content: flex-start;
      align-items: center;
    }
    @include respond(phone) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &--group {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
  }
}
