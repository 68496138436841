@use "../../styles/abstracts/variables.scss" as *;
@use "../../styles/abstracts/mixins" as *;
.event-vendors {
  // height: 80vh;
  width: 100%;
  position: relative;
  padding: 0 30px;

  @include respond(phone) {
    padding: 0;
  }

  &__loading {
    height: 50vh;
    width: 100%;
    @include flex-row;
  }

  &__remove {
    color: #e61919;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1.6rem;

    &--search {
      width: 431px;
    }
    &--btn {
      margin-left: 20px;
      width: auto;

      &__trigger {
        width: 100%;
        height: 46px;
        background-color: $col-primary;
        padding: 0px 24px;
        box-shadow: inset 0px -6px 10px #314f4a;
        @include flex-row;
        @include border-radius(4px);
        @include transition;

        & span {
          color: $col-white !important;
          font-style: normal;
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.375rem;
        }

        &:focus {
          outline: 1px dotted transparent;
          outline-offset: 1px;

          & span {
            color: $col-white;
          }
        }

        &:focus-visible {
          outline: 1px dotted $col-secondary-8;
          outline-offset: 1px;

          & span {
            color: $col-white;
          }
        }

        &:disabled,
        &[disabled] {
          @include border-radius(4px);
          background-color: $col-secondary-8;
          box-shadow: none;
          filter: none;

          &:hover,
          &:focus-within {
            background-color: $col-secondary-8;
            cursor: not-allowed;

            & span {
              color: $col-white;
            }
          }
        }

        &:hover,
        &:focus-within {
          background-color: $col-secondary-4;

          & span {
            color: $col-white;
          }
        }

        &:active {
          background-color: $col-primary;
        }
      }
    }

    @include respond(phone) {
      justify-content: flex-start;
      padding: 0rem 1.2rem 0 1.2rem;

      &--search {
        width: 100%;
      }
      &--btn {
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }

  &__main {
    margin-top: 1rem;
    overflow-x: auto;
    width: fit-content;
    width: 100%;

    @include respond(phone) {
      padding: 1.2rem 1.2rem 0 1.2rem;
    }

    &-header {
      @include flex-row;
      justify-content: flex-start;
      padding: 16px 40px;
      width: fit-content;
      height: 45px;
      align-items: center;

      & h4 {
        font-style: normal;
        text-align: left;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        text-align: left;
        width: 180px;
        color: #808080;
      }
    }

    &-grid {
      width: 100%;
    }

    &--pagination {
      position: absolute;
      top: 100%;
      left: 0;
      padding: 10px;
      background-color: $col-secondary;
      min-height: 45px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 13rem;
      width: 100%;
      font-size: 12px;

      @include respond(mini-laptop) {
        padding-right: 6rem;
      }

      @include respond(tab) {
        padding-right: 1rem;
      }

      @include respond(phone) {
        padding-right: 10px;
      }

      section {
        font-weight: 600;
        @include flex-row;
        &:first-child {
          svg {
            margin-left: 5px;
            cursor: pointer;
          }
        }
        &:last-child {
          @include flex-row;
          margin-left: 4rem;
          padding-top: 5px;
          height: auto;
          flex-wrap: wrap;
          @include respond(phone) {
            justify-content: flex-end;
          }

          div {
            margin-left: 1rem;
            cursor: pointer;
            .rotate {
              margin-left: 50px;
              svg {
                transform: rotate(180deg);
              }
            }
            @include respond(phone) {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
}
