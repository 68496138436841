@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.row {
  min-width: fit-content;
  @include flex-row;
  justify-content: flex-start;
  padding: 16px 40px;
  background: $col-secondary-2;
  margin-bottom: 0.75rem;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: $col-black;
    min-width: 180px;
    width: 20%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    text-align: left;
  }

  &__dropDown {
    &__icon {
      width: 45px;
      height: 45px;
      display: grid;
      place-items: center;
      cursor: pointer;
      border-radius: 4px;

      @include transition;

      &:hover {
        background-color: $col-secondary;
      }
    }

    &__content {
      background: $col-white;
      box-shadow: 0px 0px 2px rgba(125, 125, 125, 0.2),
        0px 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      padding: 4px;

      @include floating-transition;
    }

    &__item {
      color: #e61919;
      padding: 8px;
      cursor: pointer;

      &:hover {
        background-color: $col-secondary;
      }
    }
  }
}
