@use "../../../../styles/abstracts/mixins" as *;
@use "../../../../styles/abstracts/variables" as *;

.categoriesCard {
  border: 1px solid $col-secondary-7;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // max-height: 150px;
  align-items: center;
  width: 100%;
  padding: 24px 18px;
  gap: 16px;
  position: relative;

  &__circle {
    background: #e6e6e6;
    border: 0.5px solid #cccccc;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: $col-black;

    @include flex-row;

    &__group {
      @include flex-row;
      gap: 4px;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  &__bottom {
    @include flex-row;
    justify-content: space-between;
    width: 100%;

    & h4 {
      overflow: hidden;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      width: 80%;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__dropdown {
    &-container {
      position: relative;
      &-button {
        background-color: $col-white;
        @include border-radius(4px);
        @include row;
        width: 2rem;
        height: 2rem;
        justify-content: center;
        align-items: center;
        color: $col-black;
        font-weight: 600;
        font-style: normal;
        font-size: 0.875rem;
        line-height: 1.1875rem;

        &:hover,
        &:focus,
        &:focus-visible {
          background-color: $col-secondary;
        }
      }
    }

    &-list {
      @include grid-row(auto-fill, auto);
      padding: 0.5rem;

      & button {
        text-align: left;
      }
    }

    @include floating-transition;

    @include z-index("dropdown-menu");
    background-color: $col-white;
    box-shadow: 1px 2px 6px rgba(51, 51, 51, 0.15);
    @include border-radius(4px);
    width: fit-content;
    white-space: nowrap;
    height: auto;
    margin-top: 10px;
    &[aria-expanded="true"] {
      display: block;
    }
  }

  &__remove {
    background-color: $col-white;
    padding: 0.5rem 1rem;
    @include border-radius(4px);
    font-size: 1rem;
    color: rgb(247, 49, 49);

    &:hover,
    &:focus,
    &:focus-visible {
      background-color: $col-secondary;
    }
  }

  &__edit {
    padding: 0.5rem 1rem;
    @include border-radius(4px);
    background-color: $col-white;
    color: $col-black;
    font-size: 1rem;

    &:hover,
    &:focus,
    &:focus-visible {
      background-color: $col-secondary;
    }
  }
}
