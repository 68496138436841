@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;
.categories {
  // height: 70vh;
  width: 100%;
  position: relative;

  &__table {
    @include column;
    &-header {
      display: grid;
      grid-template-columns: 1fr 1fr 100px;
      width: 100%;
      height: 45px;
      background-color: #fafafa;
      align-items: center;
      padding-left: 40px;
      & h4 {
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1rem;
        text-align: left;
        color: #808080;
      }
    }
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    @include respond(phone) {
      margin-top: 0rem;
      padding: 0rem;
    }

    &--search {
      width: 431px;
    }
    &--btn {
      margin-left: 20px;
      width: auto;

      &__trigger {
        width: 100%;
        height: 46px;
        background-color: $col-primary;
        padding: 0px 24px;
        box-shadow: inset 0px -6px 10px #314f4a;
        @include flex-row;
        @include border-radius(4px);
        @include transition;

        & span {
          color: $col-white !important;
          font-style: normal;
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.375rem;
        }

        &:focus {
          outline: 1px dotted transparent;
          outline-offset: 1px;

          & span {
            color: $col-white;
          }
        }

        &:focus-visible {
          outline: 1px dotted $col-secondary-8;
          outline-offset: 1px;

          & span {
            color: $col-white;
          }
        }

        &:disabled,
        &[disabled] {
          @include border-radius(4px);
          background-color: $col-secondary-8;
          box-shadow: none;
          filter: none;

          &:hover,
          &:focus-within {
            background-color: $col-secondary-8;
            cursor: not-allowed;

            & span {
              color: $col-white;
            }
          }
        }

        &:hover,
        &:focus-within {
          background-color: $col-secondary-4;

          & span {
            color: $col-white;
          }
        }

        &:active {
          background-color: $col-primary;
        }
      }

      .actionlist {
        background-color: $col-white;
        position: absolute;
        height: auto;
        width: 138px;
        @include border-radius(4px);
        box-shadow: 0px 0px 2px rgba(125, 125, 125, 0.2),
          0px 2px 10px rgba(0, 0, 0, 0.1);

        li {
          cursor: pointer;
          padding: 0.7rem 1rem;
          &:hover {
            background-color: $col-secondary-2;
          }
        }
      }
    }

    @include respond(phone) {
      &--search {
        width: 100%;
      }
      &--btn {
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }

  &__main {
    margin: 1rem 0;

    @include respond(phone) {
      padding: 1.2rem 1.2rem 0 1.2rem;
    }

    &--pagination {
      padding: 10px;
      background-color: $col-secondary;
      min-height: 45px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 13rem;
      width: 100%;
      font-size: 12px;
      position: absolute;
      top: 100%;
      left: 0;

      @include respond(mini-laptop) {
        padding-right: 6rem;
      }

      @include respond(tab) {
        padding-right: 1rem;
      }

      @include respond(phone) {
        padding-right: 10px;
      }

      section {
        font-weight: 600;
        @include flex-row;
        &:first-child {
          svg {
            margin-left: 5px;
            cursor: pointer;
          }
        }
        &:last-child {
          @include flex-row;
          margin-left: 4rem;
          padding-top: 5px;
          height: auto;
          flex-wrap: wrap;
          @include respond(phone) {
            justify-content: flex-end;
          }

          div {
            margin-left: 1rem;
            cursor: pointer;
            .rotate {
              margin-left: 50px;
              svg {
                transform: rotate(180deg);
              }
            }
            @include respond(phone) {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }

  &__loading {
    height: 50vh;
    width: 100%;
    @include flex-row;
  }

  &__deck {
    @include grid-column(auto-fill, 240px);
    @include grid-row(auto-fill, auto);
    padding-top: 15px;
    grid-row-gap: 24px;
    grid-column-gap: 24px;

    @include respond(phone) {
      @include grid-column(auto-fill, 100%);
    }
  }
  &__svg-full {
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
