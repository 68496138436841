@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.AddPart {
  background-color: $col-white;
  padding: 40px;
  height: auto;
  width: 551px;
  @include column;
  gap: 20px;
  border-radius: 5px;
  height: fit-content;

  @include respond(phone) {
    width: 100vw;
    min-height: 100vh;
    @include column;
    gap: 0.75rem;
    @include z-index(fill-page);
  }

  h1 {
    margin-bottom: 1rem;
  }

  &-form {
    &_container {
      margin-bottom: 2rem;
      @include grid-row(auto-fill, auto);
      grid-row-gap: 18px;
    }
  }
  &-btnContainer {
    display: flex;
    div {
      margin-left: auto;
      button {
        width: auto;
      }
    }
  }
}
