@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.deleteBackstory {
  &--content {
    background-color: $col-white;
    padding: 40px;
    height: auto;
    width: 551px;
    display: grid;
    grid-template-rows: 60px 1fr;
    grid-row-gap: 20px;
    border-radius: 5px;
    height: fit-content;

    @include respond(phone) {
      border-radius: 0px;
      height: 100vh;
      height: -moz-available;
      height: -webkit-fill-available;
      height: calc(100vh - calc(100vh - 100%));
      width: 100vw;
      width: -moz-available;
      width: -webkit-fill-available;
      @include z-index(fill-page);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      height: 40px;
      margin-bottom: 10px;
    }
    p {
      font-size: 16px;
    }

    &-btnContainer {
      display: flex;
      width: auto;
      margin-top: 0.4rem;
      div {
        margin-left: auto;
        button {
          width: auto;
        }
      }
    }
  }
}
