@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.addCategoryModal {
  &--content {
    background-color: $col-white;
    padding: 40px;
    height: auto;
    width: 551px;
    display: grid;
    grid-template-rows: 60px 1fr;
    border-radius: 5px;
    height: fit-content;
    max-height: 85vh;
    overflow-y: auto;

    @include respond(phone) {
      max-height: unset;
      height: 100vh;
      height: -moz-available;
      height: -webkit-fill-available;
      height: calc(100vh - calc(100vh - 100%));
      width: 100vw;
      width: -moz-available;
      width: -webkit-fill-available;
      border-radius: 0px;

      @include z-index(fill-page);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: fixed;
    }

    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      height: 40px;
    }

    &-form {
      &-name {
        display: flex;
        gap: 0.5rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 100%;
        flex-wrap: wrap;
      }
      &-tag {
        border-radius: 100px;
        background: #f4f4f5;
        padding: 0.25rem 1rem;
        width: fit-content;
        max-width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &_container {
        margin-bottom: 2rem;
        @include grid-row(auto-fill, auto);
        grid-row-gap: 18px;
      }
      &_button {
        display: flex;
        font-size: 14px;
        font-weight: 400;
        justify-content: flex-end;
      }
    }
    &-btnContainer {
      display: flex;
      div {
        margin-left: auto;
        button {
          width: auto;
        }
      }
    }
  }
}
