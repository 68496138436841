@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.photobook {
  // height: 70vh;
  position: relative;
  padding: 0 30px;

  @include respond(phone) {
    padding: 0 1rem;
  }

  &__addPictures {
    background-color: $col-white;
    padding: 40px;
    height: auto;
    width: 551px;
    border-radius: 5px;

    @include respond(phone) {
      border-radius: 0px;
      height: 100vh;
      height: -moz-available;
      height: -webkit-fill-available;
      height: calc(100vh - calc(100vh - 100%));
      width: 100vw;
      width: -moz-available;
      width: -webkit-fill-available;
      @include z-index(fill-page);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &__tabs {
    height: auto;
    border-bottom: 1px solid $col-secondary-7;
    padding: 1.2rem 0 0;
    overflow-y: hidden;

    @include respond(phone) {
      // padding: 1.2rem 1.2rem 0;
    }
  }
  &__main {
    margin-top: 2rem;
  }

  &__dropdown {
    @include floating-transition;
    padding: 0.5rem;

    &-container {
      position: relative;
      &-button {
        @include border-radius(4px);
        @include row;
        justify-content: center;
        align-items: center;
        padding: 0 0.5rem;

        &:hover,
        &:focus,
        &:focus-visible {
          opacity: 0.8;
        }
      }
    }

    &-list {
      display: grid;

      & div {
        text-align: left;
        padding: 0.5rem 1rem !important;
        font-size: 1rem;
        color: $col-black;
        border-radius: 4px !important;
        cursor: pointer;

        &:hover,
        &:focus {
          background-color: #eff5f4 !important;
        }
      }

      &__remove {
        color: rgb(247, 49, 49) !important;

        &:hover,
        &:focus {
          background-color: #eff5f4 !important;
        }
      }
    }

    @include z-index("dropdown-menu");
    background-color: $col-white;
    box-shadow: 1px 2px 6px rgba(51, 51, 51, 0.15);
    @include border-radius(4px);
    width: fit-content;
    white-space: nowrap;
    height: auto;
    margin-top: 10px;
    &[aria-expanded="true"] {
      display: block;
    }
  }

  &__trigger {
    &__number {
      color: $col-secondary-4;
      font-size: 1rem;
      @include flex-row;
      justify-content: flex-start;
      width: auto;

      & span {
        margin-left: 0.2rem;
      }
    }

    &__right {
      @include flex-row;
      flex-wrap: wrap;
      justify-content: flex-end;
      flex: 1;
      gap: 5px;
      @include respond(phone) {
        justify-content: start;
      }
    }

    &__group {
      width: 100%;

      @include flex-row;
      flex-wrap: wrap-reverse;

      justify-content: space-between;
    }

    width: fit-content;
    height: 37px;
    background-color: $col-primary;
    padding: 0px 24px;
    box-shadow: inset 0px -6px 10px #314f4a;
    @include flex-row;
    @include border-radius(9.52px);
    @include transition;

    & span {
      color: $col-white !important;
      font-style: normal;
      font-weight: 600;
      font-size: 14.58px;
      line-height: 1.375rem;
    }

    &:focus {
      outline: 1px dotted transparent;
      outline-offset: 1px;

      & span {
        color: $col-white;
      }
    }

    &:focus-visible {
      outline: 1px dotted $col-secondary-8;
      outline-offset: 1px;

      & span {
        color: $col-white;
      }
    }

    &:disabled,
    &[disabled] {
      @include border-radius(4px);
      background-color: $col-secondary-8;
      box-shadow: none;
      filter: none;

      &:hover,
      &:focus-within {
        background-color: $col-secondary-8;
        cursor: not-allowed;

        & span {
          color: $col-white;
        }
      }
    }

    &:hover,
    &:focus-within {
      background-color: $col-secondary-4;

      & span {
        color: $col-white;
      }
    }

    &:active {
      background-color: $col-primary;
    }
  }

  &__add-pictures {
    width: fit-content;

    &-container {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;
    }
  }

  &__delete-selected {
    width: 100%;
    height: 46px;
    background-color: #b91c1c;
    padding: 0px 24px;
    cursor: pointer;
    @include flex-row;
    @include border-radius(4px);
    @include transition;

    & span {
      color: $col-white;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.375rem;
    }

    &:focus {
      outline-offset: 1px;
      outline: 1px dotted transparent;
    }

    &:focus-visible {
      outline-offset: 1px;
      outline: 1px dotted $col-secondary-8;
    }

    &:disabled,
    &[disabled] {
      @include border-radius(4px);
      background-color: $col-secondary-8;
      box-shadow: none;
      filter: none;

      &:hover,
      &:focus-within {
        background-color: $col-secondary-8;
        cursor: not-allowed;
      }
    }

    &:hover,
    &:focus-within {
      background-color: $col-fail;
    }

    &:active {
      background-color: $col-fail;
    }
  }

  &__container {
    margin-top: 50px;
    // grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    // grid-gap: 20px;
    gap: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    // @include grid-row(auto-fill, 250px);
    // grid-auto-rows: minmax(min-content, max-content);
    height: 100%;
    @include respond(phone) {
      margin-top: 16px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 23px;
    }
  }

  &__card {
    height: 260px;
    width: 260px;
    border-radius: 8px;
    overflow: hidden;
    @include respond(phone) {
      height: 100%;
      width: 100%;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__container {
      height: 260px;
      position: relative;
      width: 260px;
      overflow: hidden;
      border-radius: 8px;
      @include respond(phone) {
        height: 100%;
        width: 100%;
      }

      & button {
        height: 100%;
      }
    }

    &__checkbox {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 2;

      & input {
        position: absolute;
        opacity: 0;
        width: 18.52px;
        height: 18.52px;
        cursor: pointer;
      }

      & label {
        position: relative;
        display: inline-block;
        width: 18.52px;
        height: 18.52px;
        background-color: white;
        border: 2px solid #8080801a;
        cursor: pointer;
        border-radius: 4px;
      }

      & label::after {
        content: "";
        position: absolute;
        display: none;
        left: 5px;
        top: 1.5px;
        width: 6px;
        height: 10px;
        border: solid #006554;
        border-width: 0 2.5px 2.5px 0;
        border-radius: 1px;
        transform: rotate(45deg);

        &::before {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          background: #006554;
          border-radius: 50%;
          left: 3px;
          top: 0;
        }

        &::after {
          content: "";
          position: absolute;
          width: 6px;
          height: 6px;
          border: solid #006554;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg);
          left: 1px;
          top: 4px;
          border-radius: 1px;
        }
      }

      & input:checked + label {
        background-color: #cdd4cf;
        border: 2px solid #ffffff;
      }

      // Show checkmark when checkbox is checked
      & input:checked + label::after {
        display: block;
      }
    }

    &__picture {
      position: absolute;
      bottom: 4px;
      left: 8px;
      z-index: 2;
    }

    &__delete {
      position: absolute;
      top: 4px;
      right: 4px;
      width: 32px;
      height: 32px;
      z-index: 2;
      @include flex-row;

      border-radius: 4px;
      @include transition;
      transition-duration: 0.1s;
      cursor: pointer;

      @include respond(phone) {
        top: -4px;
        right: -4px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__loading {
    height: 40vh;
    width: 100%;
    @include flex-row;
  }
}
