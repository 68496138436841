@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.confirmPin {
  &__title {
    @include grid-row(2, auto);
    grid-row-gap: 8px;
    padding-bottom: 2rem;

    & h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      height: 40px;
      margin-bottom: 10px;
    }

    & p {
      padding-top: 0.5rem;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      color: $col-black;

      @include respond(big-tab) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &__main {
    display: grid;
    width: 100%;
    height: 100%;

    &-container {
      grid-column: 2/3;
      background-color: $col-white;
      justify-content: flex-start;
      height: 100%;
      padding: 40px;
      gap: 5px;
      border-radius: 5px;

      @include column;

      &-inner {
        @include grid-row(2, auto);
        grid-row-gap: 5px;
      }
    }
  }

  &__text {
    margin-top: 40px;
    font-size: 1rem;
    line-height: 1.375rem;
    font-style: normal;
    font-weight: normal;
    color: $col-black;
  }

  &__button {
    margin-top: 40px;
    margin-bottom: 13px;
    // width: 55%;
  }

  &__field {
    @include grid-column(5, 60px);
    grid-column-gap: 8px;
    margin: 0 auto;
    place-items: center;
    width: fit-content;

    @include respond(phone) {
      @include grid-column(5, 1fr);
    }

    &-input {
      background-color: $col-white;
      border: 1px solid $col-secondary-9;
      width: 100%;
      height: 78px;

      @include border-radius(2px);

      font-size: 2.25rem;
      line-height: 3.0625rem;
      font-style: normal;
      font-weight: normal;
      color: $col-black;
      text-align: center;

      &:focus {
        border: 1px solid $col-secondary-10;
        caret-color: $col-secondary-10;
      }
    }
  }
}
