@use "../../../styles/abstracts/variables.scss" as *;
@use "../../../styles/abstracts/mixins" as *;

.addendumCard {
  &__container {
    padding: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #f4f4f5;
    border-radius: 4px;

    &__added {
      border: 1px solid #e4e4e7;
    }
  }

  &__checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid #d4d4d8;
    border-radius: 2px;

    &__checked {
      background: #d4d4d8;
    }
  }

  &__deck {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0px;
    width: 80%;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    color: #141414;
    text-align: left;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    float: left;

    &__checked {
      color: #a1a1aa;
      text-decoration: line-through;
    }
  }

  &__sub-text {
    font-weight: 400;
    font-size: 14px;
    color: #999999;

    @include respond(phone) {
      display: none;
    }

    &__checked {
      color: #a1a1aa;
      text-decoration: line-through;
    }
  }
}
