@use "../../../../styles/abstracts/variables" as *;
@use "../../../../styles/abstracts/mixins" as *;

.wishlist__deleteItem {
  background-color: $col-white;
  padding: 40px;
  height: auto;
  width: 591px;
  border-radius: 5px;

  @include respond(phone) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    height: -moz-available;
    height: -webkit-fill-available;
    height: calc(100vh - calc(100vh - 100%));
    width: 100vw;
    width: -moz-available;
    width: -webkit-fill-available;
    position: fixed;
  }

  &-button {
    width: 165px;
    display: flex;
    justify-self: flex-end;
    align-self: center;
  }
  &-container {
    @include grid-row(2, auto);
    grid-row-gap: 32px;
  }
  &-title {
    @include grid-row(2, auto);
    grid-row-gap: 16px;
    & h4 {
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 2rem;
      text-align: left;
      color: $col-black;
    }
    & p {
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: left;
    }
  }
}
