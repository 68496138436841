@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.nairaWallet {
  position: relative;
  margin-top: 2rem;
  padding: 0 1.2rem;
  height: 100%;

  &__loading {
    height: 50vh;
    width: 100%;
    @include flex-row;
  }

  &__emptyState {
    @include flex-row;
    height: 100%;
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    max-width: 780px;
    margin: 0 auto;

    button {
      width: auto;
    }

    &__icon {
      padding: 0.6rem 0.8rem;
      border-radius: 4px;
      background: $col-white;
      box-shadow: 0px 0px 2px;
      font-weight: 500;

      @include transition;

      &:hover {
        background: $col-secondary-2;
      }
    }

    &__trigger {
      width: 100%;
      height: 55px;
      background-color: #3b5f59;
      padding: 0px 24px;
      box-sizing: border-box;
      border-radius: 4px;
      @include flex-row;
      @include border-radius(4px);
      @include transition;

      & span {
        color: $col-white;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.375rem;
      }

      &:focus {
        outline: 1px dotted transparent;
        outline-offset: 1px;
      }

      &:focus-visible {
        outline: 1px dotted $col-black;
        outline-offset: 1px;
      }

      &:disabled,
      &[disabled] {
        @include border-radius(4px);
        background-color: $col-secondary-8;
        box-shadow: none;
        filter: none;

        &:hover,
        &:focus-within {
          background-color: #3b5f59;
          cursor: not-allowed;
        }
      }

      &:hover,
      &:focus-within {
        background-color: #3b5f59;
      }

      &:active {
        background-color: #3b5f59;
      }
    }
  }

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $col-primary;
    border-radius: 8px;
    min-height: 220px;
    z-index: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &__image {
      object-fit: cover;
    }
  }

  &__main {
    width: 100%;
    margin: 1rem 0;

    @include column;

    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;

    &__top {
      position: relative;
      @include column;
      justify-content: center;
      gap: 3rem;
      align-items: center;
      background: #3b5f59;
      border-radius: 8px;
      margin: 0 auto;
      max-width: 401px;
      height: 242px;
      width: 100%;
      overflow: hidden;
    }

    &__content {
      width: 100%;
      margin-bottom: 1rem;
      max-width: 1000px;
      @include column;

      justify-content: center;
      align-items: center;
      gap: 0.75rem;

      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #000000;

      &__text {
        flex-wrap: wrap;
        gap: 0.5rem;
        padding-top: 24px;
        max-width: 1000px;
        width: 100%;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #25213b;
        text-align: left;
      }

      &__item {
        @include flex-space-between;
        flex-wrap: wrap;
        gap: 0.5rem;
        padding: 24px;
        background: #fafafa;
        width: 100%;
        cursor: pointer;
        transition: all 0.3s ease-out;

        &:hover {
          background: $col-secondary;
        }

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: #25213b;

          span {
            font-weight: 400;
          }
        }

        div {
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
          text-align: right;
        }

        &-credit {
          color: $col-secondary-14;
        }

        &-debit {
          color: $col-secondary-19;
        }
      }
    }

    &__context {
      display: flex;
      justify-content: center;
      position: relative;
      width: 100%;
      max-width: 401px;

      @include respond(mini-laptop) {
        gap: 1.5rem;
      }
    }

    &__dialog {
      display: flex;
      gap: 2rem;

      &-pop {
        padding-left: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        height: 100%;
        right: 0;

        @include respond(mini-laptop) {
          padding-left: 0rem;
        }
      }
    }

    &__empty {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      justify-content: center;
      align-items: center;
    }

    &__p {
      padding-top: 2rem;
      text-align: center;
    }

    &__icon {
      padding: 0.75rem 1.25rem;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include transition;

      &:hover {
        background-color: $col-secondary-2;
      }
    }

    &__block {
      @include column;
      display: grid;
      grid-template-rows: 70% 20%;
      height: 100%;
      align-items: center;
      border-radius: 8px;
      z-index: 2;

      &__nairaAcct {
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          font-weight: 400;
          font-size: 12px;
          color: $col-white;
        }

        h1 {
          font-weight: 700;
          line-height: 39px;
          font-size: 32px;
          text-align: center;
          color: #ffffff;
        }
      }

      &__foreignAcct {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-self: end;
        gap: 0.5rem;

        &__alert {
          color: rgba(255, 255, 255, 0.3);
        }
      }

      &__group {
        @include column;
        justify-content: flex-start;
        align-items: center;
        text-transform: capitalize;

        h3 {
          font-weight: 700;
          font-size: 20px;
          line-height: 27px;
        }

        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
        }

        h4 {
          font-weight: 700;
          margin-top: 0.75rem;
          font-size: 16px;
          line-height: 22px;
        }
      }

      &__acc-number {
        background: #ffffff;
        border: 0.5px solid #ffffff;
        border-radius: 4px;
        padding: 12px 24px;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #000000;
        gap: 1rem;
        @include flex-row;
        align-items: flex-start;

        svg {
          cursor: pointer;
        }
      }
    }

    &__foreignAcct__btn {
      background: rgba(255, 255, 255, 0.3);
      border-radius: 41px;
      width: 41px;
      height: 24px;
      display: grid;
      place-items: center;
      color: $col-white !important;
      font-weight: 400;
      font-size: 8px;
      cursor: pointer;
    }
  }

  &__dropdown {
    display: flex;
    flex-direction: column;
    @include floating-transition;
    padding: 0.5rem;

    &__button {
      padding: 0.5rem 1rem;
      border-radius: 5px;
      text-align: start;
      @include transition;

      &:hover {
        background-color: $col-secondary-2;
      }
    }

    &-container {
      position: relative;
      &-button {
        background-color: $col-white;
        @include border-radius(4px);
        @include row;
        width: 2rem;
        height: 2rem;
        justify-content: center;
        align-items: center;
        color: $col-black;
        font-weight: 600;
        font-style: normal;
        font-size: 0.875rem;
        line-height: 1.1875rem;

        &:hover,
        &:focus,
        &:focus-visible {
          background-color: $col-secondary;
        }
      }
    }

    &-list {
      display: grid;

      & button {
        text-align: left;
        padding: 0.5rem 1rem !important;
        font-size: 1rem;
        color: $col-black;
        border-radius: 4px !important;

        &:hover,
        &:focus {
          background-color: #eff5f4 !important;
        }
      }

      &__remove {
        color: rgb(247, 49, 49);
      }
    }

    @include z-index("dropdown-menu");
    background-color: $col-white;
    box-shadow: 1px 2px 6px rgba(51, 51, 51, 0.15);
    @include border-radius(4px);
    width: fit-content;
    white-space: nowrap;
    height: auto;
    margin-top: 10px;
    &[aria-expanded="true"] {
      display: block;
    }
  }
}
