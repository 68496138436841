@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.RemoveGuest {
  background-color: $col-white;
  padding: 40px;
  width: 551px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 5px;
  gap: 1rem;
  height: fit-content;

  @include respond(phone) {
    max-height: unset;
    height: 100vh;
    height: -moz-available;
    height: -webkit-fill-available;
    height: calc(100vh - calc(100vh - 100%));
    width: 100vw;
    width: -moz-available;
    width: -webkit-fill-available;
    border-radius: 0px;
    position: fixed;
    @include z-index(fill-page);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
  }

  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    height: 40px;
  }
  p {
    font-size: 16px;
  }

  &--btn {
    width: fit-content;
    place-self: flex-end;
    margin-top: 0.4rem;

    div {
      margin-left: auto;
      button {
        width: auto;
      }
    }
  }
}
