@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.EmptyState {
  width: 90%;
  margin: auto;

  @include respond(phone) {
    width: 100%;
  }

  &--title {
    color: $col-secondary-28;
    font-size: 16px;
    font-weight: normal;
    margin-left: 10px;
    width: 90%;
  }

  &--main {
    @include flex-column;
    margin-top: 4rem;

    p {
      margin-bottom: 1rem;
      margin-top: 1rem;
      font-size: 18px;
    }

    button {
      width: auto;
    }

    &__trigger {
      width: 100%;
      height: 46px;
      background-color: $col-primary;
      padding: 0px 24px;
      box-shadow: inset 0px -6px 10px #314f4a;
      @include flex-row;
      @include border-radius(4px);
      @include transition;

      & span {
        color: $col-white !important;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.375rem;
      }

      &:focus {
        outline: 1px dotted transparent;
        outline-offset: 1px;

        & span {
          color: $col-white;
        }
      }

      &:focus-visible {
        outline: 1px dotted $col-secondary-8;
        outline-offset: 1px;

        & span {
          color: $col-white;
        }
      }

      &:disabled,
      &[disabled] {
        @include border-radius(4px);
        background-color: $col-secondary-6;
        box-shadow: none;
        filter: none;

        &:hover,
        &:focus-within {
          background-color: $col-secondary-8;
          cursor: not-allowed;

          & span {
            color: $col-white;
          }
        }
      }

      &:hover,
      &:focus-within {
        background-color: $col-secondary-4;

        & span {
          color: $col-white;
        }
      }

      &:active {
        background-color: $col-primary;
      }
    }
  }
}
