@use "src/styles/abstracts/mixins" as *;
@use "src/styles/abstracts/variables" as *;

.templates {
  width: 100%;
  min-height: 70vh;
  position: relative;

  &__main {
    @include column;
    width: 100%;
    height: 100%;
    grid-column: 1/3;

    @include respond(phone) {
      margin-top: 0px;
      padding: 0 1.2rem;
    }

    &-template {
      @include column;
      border: 1px solid #f2f2f2;
      overflow-y: auto;
      height: 100%;
      border-radius: 4px;

      @include respond(phone) {
        border: none;
      }

      &-container {
        @include column;
        padding-top: 24px;
      }

      &-title {
        width: 100%;
        height: 74px;
        display: flex;
        align-items: center;
        padding: 16px 24px;
        border-bottom: 1px solid #f2f2f2;

        @include respond(phone) {
          padding: 0 0 0.5rem 0;
          margin-top: -1.5rem;
        }

        & h5 {
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          text-align: left;
          color: $col-black;
        }
      }
    }

    &-content {
      display: grid;
      grid-template-columns: 316px 1fr;
      grid-column-gap: 24px;
      height: 100%;

      @include respond(phone) {
        grid-template-columns: 1fr;
        // margin-top: 24px;
        height: calc(100vh - 300px);
      }
    }

    &-views {
      @include flex-row;

      &-box {
        @include grid-column(2, auto);
        grid-column-gap: 12px;
      }

      &-orientation {
        background: #34544f;
        border-radius: 3px;
        width: 52px;
        height: 52px;
        border: 1px solid #34544f;

        @include border-radius(4px);
        @include flex-row;
        @include transition;

        &__active {
          background: #273f3b;
          border: 1px solid #4e7e77;
          border-radius: 4px;
        }
      }
    }

    &-preview {
      position: relative;
      border: 1px solid #34544f;
      border-radius: 4px;
      width: 100%;
      min-width: 550px;
      height: 100%;
      background-color: $col-primary;
      padding: 34px 40px 0px 40px;
      display: grid;
      grid-template-rows: auto 1fr;
      grid-row-gap: 20px;

      @include respond(phone) {
        position: fixed;
        height: 100%;
        width: 100%;

        @include z-index(fill-page);
        top: 0;
        left: 0;
        display: none;
        padding: 30px 18px;
        &[data-display="true"] {
          display: block;
          border-radius: 0%;
        }
      }
      & h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 1.5625rem;
        color: $col-white;
      }
      &-title {
        @include column;
        align-items: flex-start;

        @include respond(phone) {
          margin-bottom: 1rem;
        }

        & button {
          margin-bottom: 14px;
          & svg path {
            stroke: $col-white !important;
          }
        }
      }
      &-box {
        border: 0.5px solid #000000;
        background-color: $col-white;
        display: flex;
        justify-self: center;
        justify-content: center;
        align-items: center;
        position: relative;

        &__landscape {
          height: 57vh;
          width: 400px;
        }

        &__portrait {
          height: 57vh;
          width: 300px;
        }

        &__showcase {
          width: 100% !important;
          height: auto !important;
          position: absolute;
          top: 0;
        }

        @include transition;

        @include respond(phone) {
          width: 100%;
          height: 400px;
        }

        &[data-image="true"] {
          background-color: transparent;
          border: none;
        }

        &-image {
          width: 100%;
          height: 100%;
        }

        & div {
          width: 70px;
          height: 70px;
        }
        &-container {
          overflow-y: auto;
          z-index: 1;
          width: 100%;
          height: 57vh;
          @include flex-row;
          @include respond(phone) {
            display: block;
          }
        }
      }

      &__top {
        @include flex-row;
        width: 100%;
        gap: 2rem;
        z-index: 1;

        & h2 {
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.5625rem;
          text-align: left;
        }
      }

      &__buttons {
        gap: 0.75rem;
        @include flex-row;
      }
    }
  }
  &__alert {
    &-save-button {
      height: 43px;
      width: fit-content;

      button {
        border: 1px solid #34544f;
        background-color: #34544f !important;

        span {
          white-space: nowrap;
          color: $col-white;
        }

        &:hover,
        &:focus,
        &:active {
          color: $col-white;
          background-color: #34544f !important;
          border: 1px solid #34544f;
        }
      }

      @include respond(phone) {
        display: none;
      }
    }

    &-upload-button {
      height: 43px;
      width: fit-content;

      &__actual {
        width: 100%;
        height: 46px;
        background-color: $col-white;
        padding: 0px 24px;
        border: 1px solid #bfbfbf;
        box-sizing: border-box;
        border-radius: 4px;

        @include flex-row;
        @include border-radius(4px);
        @include transition;

        color: $col-white;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.375rem;
        white-space: nowrap;
        background-color: transparent !important;
        border: 1px solid #629e94;

        &:focus {
          outline: 1px dotted transparent;
          outline-offset: 1px;
        }

        &:focus-visible {
          outline: 1px dotted $col-black;
          outline-offset: 1px;
        }

        &:hover,
        &:active {
          color: $col-white;
          border: 1px solid #629e94;
          background-color: transparent !important;
        }
      }
    }
    &-heading-container {
      display: grid;
      grid-template-columns: auto auto;
      grid-column-gap: 68px;
      align-items: center;
      justify-content: flex-end;
      @include respond(phone) {
        grid-template-columns: 1fr;
      }
    }
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    @include respond(phone) {
      display: block;
    }
    &-container {
      width: 100%;
      height: 73px;
      background-color: $col-secondary-23;
      border: 1px dashed $col-secondary-24;
      padding: 0px 40px;
      @include border-radius(2px);
      @include flex-row;
      @include respond(phone) {
        width: 100%;
        height: auto;
        padding: 28px 19px;
      }
      &-box {
        @include grid-column(2, auto);
        grid-column-gap: 10px;
        @include grid-center;
        font-style: normal;
        font-weight: normal;
        font-size: 1.125rem;
        line-height: 1.5625rem;
        color: $col-secondary-25;
        @include respond(phone) {
          font-size: 1rem;
          line-height: 1.375rem;
        }
        & button {
          font-style: normal;
          font-weight: 600;
          font-size: 1.125rem;
          line-height: 1.5625rem;
          color: $col-secondary-25;
          text-decoration: underline;
          margin-left: 5px;
          @include respond(phone) {
            font-size: 1rem;
            line-height: 1.375rem;
          }
        }
      }
    }
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr; // Come back here
    background-color: $col-white;
    width: 100%;
    // height: 70vh;
    overflow-y: hidden;

    @include respond(phone) {
      grid-template-columns: 1fr;
    }
  }
  display: grid;
  grid-template-columns: 1fr;

  @include respond(phone) {
    grid-template-columns: 15px 1fr 15px;
  }

  &__button {
    @include grid-column(2, auto);
    grid-column-gap: 16px;
    align-items: center;

    @include respond(phone) {
      display: none;
    }

    &-preview {
      width: fit-content;
    }
  }

  &__dropdown {
    &-container {
      position: relative;
      &-button {
        background-color: $col-white;
        border: 1px solid $col-secondary-8;
        @include border-radius(4px);
        padding: 0px 24px;
        width: fit-content;
        height: 43px;
        @include row;
        align-items: center;
        color: $col-black;
        font-weight: 600;
        font-style: normal;
        font-size: 0.875rem;
        line-height: 1.1875rem;
      }
    }
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    @include z-index("dropdown-menu");
    background-color: $col-white;
    box-shadow: 1px 2px 6px rgba(51, 51, 51, 0.15);
    @include border-radius(4px);
    width: 150px;
    height: auto;
    padding: 20px 32px 20px 20px;
    margin-top: 10px;
    &[aria-expanded="true"] {
      @include grid-center;
    }

    &-icon {
      margin-left: 8px;
    }
  }

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $col-primary;
    min-height: 280px;
    z-index: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &__image {
      object-fit: cover;
    }
  }
}
