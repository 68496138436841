@use "../../styles/abstracts/variables.scss" as *;
@use "../../styles/abstracts/mixins" as *;

.button {
  width: 100%;
  height: 46px;
  background-color: $col-primary;
  padding: 0px 24px;
  cursor: pointer;
  box-shadow: inset 0px -6px 10px #314f4a;
  @include flex-row;
  @include border-radius(8px);
  @include transition;

  & span {
    color: $col-white;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
  }

  &:focus {
    outline-offset: 1px;
    outline: 1px dotted transparent;
  }

  &:focus-visible {
    outline-offset: 1px;
    outline: 1px dotted $col-secondary-8;
  }

  &:disabled,
  &[disabled] {
    @include border-radius(8px);
    background-color: $col-secondary-8;
    box-shadow: none;
    filter: none;

    &:hover,
    &:focus-within {
      background-color: $col-secondary-8;
      cursor: not-allowed;
    }
  }

  &:hover,
  &:focus-within {
    background-color: $col-secondary-4;
  }

  &:active {
    background-color: $col-primary;
  }
}

.button--orange {
  width: 100%;
  height: 46px;
  background-color: #ffb800;
  padding: 0px 24px;
  cursor: pointer;
  @include flex-row;
  @include border-radius(8px);
  @include transition;

  & span {
    color: $col-black;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
  }

  &:focus {
    outline-offset: 1px;
    outline: 1px dotted transparent;
  }

  &:focus-visible {
    outline-offset: 1px;
    outline: 1px dotted #ffb800;
  }

  &:disabled,
  &[disabled] {
    @include border-radius(8px);
    background-color: $col-secondary-8;
    box-shadow: none;
    filter: none;

    &:hover,
    &:focus-within {
      background-color: $col-secondary-8;
      cursor: not-allowed;
    }
  }

  &:hover,
  &:focus-within {
    background-color: #f2af03;
  }

  &:active {
    background-color: #f2af03;
  }
}

.button--tertiary {
  width: 100%;
  height: 46px;
  background-color: #b91c1c;
  padding: 0px 24px;
  cursor: pointer;
  @include flex-row;
  @include border-radius(8px);
  @include transition;

  & span {
    color: $col-white;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
  }

  &:focus {
    outline-offset: 1px;
    outline: 1px dotted transparent;
  }

  &:focus-visible {
    outline-offset: 1px;
    outline: 1px dotted $col-fail;
  }

  &:disabled,
  &[disabled] {
    @include border-radius(8px);
    background-color: $col-secondary-8;
    box-shadow: none;
    filter: none;

    &:hover,
    &:focus-within {
      background-color: $col-secondary-8;
      cursor: not-allowed;
    }
  }

  &:hover,
  &:focus-within {
    background-color: $col-fail;
  }

  &:active {
    background-color: $col-fail;
  }
}

.button--secondary {
  width: 100%;
  height: 46px;
  background-color: $col-white;
  padding: 0px 24px;
  border: 1px solid #bfbfbf;
  box-sizing: border-box;
  border-radius: 4px;
  @include flex-row;
  @include border-radius(8px);
  @include transition;

  & span {
    color: $col-black;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
  }

  &:focus {
    outline-offset: 1px;
    outline: 1px dotted transparent;
  }

  &:focus-visible {
    outline-offset: 1px;
    outline: 1px dotted $col-secondary-8;
  }

  &:disabled,
  &[disabled] {
    @include border-radius(8px);
    background-color: $col-secondary-8;
    box-shadow: none;
    filter: none;

    &:hover,
    &:focus-within {
      background-color: $col-secondary-8;
      cursor: not-allowed;
    }

    & span {
      color: $col-white;
    }
  }

  &:hover,
  &:focus-within {
    background-color: $col-secondary-2;
  }

  &:active {
    background-color: $col-secondary-2;
  }
}
