@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.addDetailsModal {
  &--content {
    background-color: $col-white;
    padding: 40px;
    height: auto;
    width: 551px;
    @include column;
    gap: 20px;
    border-radius: 5px;
    height: fit-content;
    max-height: 85vh;
    overflow-y: auto;

    @include respond(phone) {
      max-height: unset;
      height: 100vh;
      height: -moz-available;
      height: -webkit-fill-available;
      height: calc(100vh - calc(100vh - 100%));
      width: 100vw;
      width: -moz-available;
      width: -webkit-fill-available;
      border-radius: 0px;

      @include z-index(fill-page);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: fixed;
    }

    &__top {
      @include column;
      gap: 0.75rem;
    }

    &__sub {
      display: flex;
      height: fit-content;
      margin-bottom: 0.75rem;
    }

    &-form {
      &_container {
        margin-bottom: 2rem;
        @include grid-row(auto-fill, auto);
        grid-row-gap: 18px;
      }
    }
    &-btnContainer {
      display: flex;
      div {
        margin-left: auto;
        button {
          width: auto;
        }
      }
    }
  }
}
