@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  & + label {
    display: inline-block;
    color: $col-primary;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.375rem;
    text-align: center;
  }
}

.file + label {
  cursor: pointer;
}
