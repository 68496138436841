.index__loader {
  height: 100vh;
  width: 100%;
  background-color: #34544f;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  &-dots {
    width: 3em;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    div {
      width: 0.75em;
      height: 0.75em;
      border-radius: 50%;
      background-color: #fff;
      animation: fade 0.8s ease-in-out alternate infinite;
    }

    div:nth-of-type(1) {
      animation-delay: -0.4s;
    }

    div:nth-of-type(2) {
      animation-delay: -0.2s;
    }

    @keyframes fade {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }

  &__hidden {
    overflow: hidden;
  }

  &__logo {
    width: 6rem;
    height: auto;
  }
}
