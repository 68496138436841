@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.dropdown-card {
  &__dropdown {
    @include floating-transition;

    @include z-index("dropdown-menu");

    background-color: $col-white;
    box-shadow: 1px 2px 6px rgba(51, 51, 51, 0.15);
    border: 1px solid #e4e4e7;
    @include border-radius(4px);
    width: fit-content;
    white-space: nowrap;
    height: auto;
    margin-top: 10px;
    &[aria-expanded="true"] {
      display: block;
    }
  }
}
