@use "../../styles/abstracts/variables.scss" as *;
@use "../../styles/abstracts/mixins" as *;

.select {
  @include column;
  width: 100%;
  height: auto;

  & label {
    margin-bottom: 4px;
    color: $col-black;
    font-size: 16px;
    line-height: 22px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  &__field {
    position: relative;
    background-color: transparent;
    border: 1px solid transparent;
    width: 100%;
    // height: 50px;

    &[data-input="true"] {
      position: relative;
      background-color: $col-secondary-2;

      @include border-radius(2px);
      @include row;
    }

    &[data-focused="true"] {
      border-bottom: 1px solid $col-primary;
      border-radius: 0px 0px 2px 2px;
    }

    // @include respond(big-tab) {
    //   height: 48px;
    // }

    & input,
    & textarea {
      width: 100%;
      height: 100%;
      outline: 0;
      padding: 0.8rem 1.5rem;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      padding-right: 3rem;
      border: 1px solid $col-secondary;
      background-color: $col-secondary-2;

      @include border-radius(2px);
      @include flex-space-between;

      &::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.375rem;
        color: $col-secondary-9;
      }
    }
    & textarea {
      padding: 10px;
      resize: none;
    }
  }
}
