@use "../../../../../styles/abstracts/mixins" as *;
@use "../../../../../styles/abstracts/variables" as *;

.liveHallList {
  width: 100%;
  @include flex-row;
  justify-content: flex-start;
  padding: 16px 40px;
  background: $col-secondary-2;
  gap: 0.75rem;
  margin-bottom: 0.5rem;

  @include respond(phone) {
    width: fit-content;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: $col-black;
    width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    text-align: left;
    min-width: 180px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:last-of-type {
      text-transform: capitalize;
    }
  }

  &__online {
    color: #00ae07 !important;
  }

  &__offline {
    color: #71717a !important;
  }

  &__dropdown {
    &-end {
      min-width: auto !important;
    }

    &-container {
      flex: 1;
      position: relative;
      display: flex;
      justify-content: flex-end;
      min-width: 180px;

      &-button {
        background-color: $col-secondary-2;
        @include border-radius(4px);
        @include row;
        width: 2rem;
        height: 2rem;
        justify-content: center;
        align-items: center;
        color: $col-black;
        font-weight: 600;
        font-style: normal;
        font-size: 0.875rem;
        line-height: 1.1875rem;

        &:hover,
        &:focus,
        &:focus-visible {
          background-color: $col-secondary;
        }
      }
    }

    &-list {
      @include grid-row(auto-fill, auto);
      padding: 0.5rem;

      & button {
        text-align: left;
        font-size: 1rem;
      }
    }

    @include floating-transition;

    @include z-index("dropdown-menu");
    background-color: $col-white;
    box-shadow: 1px 2px 6px rgba(51, 51, 51, 0.15);
    @include border-radius(4px);
    width: fit-content;
    white-space: nowrap;
    height: auto;
    margin-top: 10px;
    &[aria-expanded="true"] {
      display: block;
    }
  }

  &__remove {
    background-color: $col-white;
    padding: 0.5rem 1rem;
    color: rgb(247, 49, 49);
    border-radius: 4px;

    &:hover,
    &:focus,
    &:focus-visible {
      background-color: $col-secondary;
    }
  }

  &__edit {
    padding: 0.5rem 1rem;
    background-color: $col-white;
    border-radius: 4px;
    color: $col-black;

    &:hover,
    &:focus,
    &:focus-visible {
      background-color: $col-secondary;
    }
  }
}
