@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.addStreamer {
  background-color: $col-white;
  padding: 40px;
  width: 551px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 5px;
  height: fit-content;
  gap: 0.75rem;

  @include respond(phone) {
    max-height: unset;
    height: 100vh;
    height: -moz-available;
    height: -webkit-fill-available;
    height: calc(100vh - calc(100vh - 100%));
    width: 100vw;
    width: -moz-available;
    width: -webkit-fill-available;
    border-radius: 0px;
    position: fixed;
    @include z-index(fill-page);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
  }

  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    height: 40px;
  }
  p {
    text-align: left;
    margin-bottom: 0.5rem;
    font-size: 16px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    background: #fafafa;
    border: 1px solid #fafafa;

    @include transition;

    &:hover {
      border: 1px solid #34544f;
    }
  }

  &--content__none {
    border: 0px !important;
    text-align: center !important;
    background: none !important;
    &:hover {
      border: 0px !important;
    }
  }

  &__list {
    width: 100%;
    max-height: 240px;

    @include respond(phone) {
      max-height: 70vh;
    }

    &__item {
      @include flex-row;

      background-color: $col-white;
      padding: 1rem 0;
      justify-content: space-between;

      & button:hover {
        background-color: $col-secondary-2;
      }

      &__selected {
        & p {
          border: 1px solid #34544f;
          background: none !important;
        }
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      button {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: $col-black;
        border: 1px solid $col-secondary-35;
        border-radius: 2px;
        padding: 8px 12px;

        &:disabled,
        &[disabled] {
          background-color: $col-secondary-8;
          filter: opacity(0.8);
          cursor: not-allowed;
        }
      }
    }

    &__scrollbar {
      width: 8px;
      background: #fafafa;
      border-radius: 30px;
      height: 100%;
    }

    &__thumb {
      width: 8px;
      height: 26px;
      background: #34544f;
      border-radius: 30px;
    }
  }

  &--btn {
    width: fit-content;
    place-self: flex-end;
    margin-top: 0.4rem;

    div {
      margin-left: auto;
      button {
        width: auto;
      }
    }
  }
}
