@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.MyParts {
  position: relative;
  margin-top: 2rem;
  padding: 0 1.2rem;

  &__emptyState {
    @include flex-row;
    height: 100%;
  }

  &__header {
    display: flex;
    justify-content: flex-end;

    button {
      width: auto;
    }

    &__trigger {
      width: 100%;
      height: 46px;
      background-color: $col-primary;
      padding: 0px 24px;
      box-shadow: inset 0px -6px 10px #314f4a;
      @include flex-row;
      @include border-radius(4px);
      @include transition;

      & span {
        color: $col-white !important;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.375rem;
      }

      &:focus {
        outline: 1px dotted $col-secondary-8;
        outline-offset: 1px;

        & span {
          color: $col-white;
        }
      }

      &:disabled,
      &[disabled] {
        @include border-radius(4px);
        background-color: $col-secondary-8;
        box-shadow: none;
        filter: none;

        &:hover,
        &:focus-within {
          background-color: $col-secondary-8;
          cursor: not-allowed;

          & span {
            color: $col-white;
          }
        }
      }

      &:hover,
      &:focus-within {
        background-color: $col-secondary-4;

        & span {
          color: $col-white;
        }
      }

      &:active {
        background-color: $col-primary;
      }
    }
  }

  &__main {
    width: 100%;
    height: auto;
    display: grid;
    // @include grid-column(3, 1fr);
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    column-gap: 30px;
    row-gap: 30px;
    margin-top: 1rem;

    @include respond(tab) {
      grid-template-columns: minmax(10px, 3fr) 3fr;
      row-gap: 25px;
    }
    @include respond(phone) {
      grid-template-columns: 1fr;
      row-gap: 25px;
    }
  }
  &__loading {
    height: 50vh;
    width: 100%;
    @include flex-row;
  }
}

.empty-state {
  @include flex-column;
  margin: auto;

  p {
    margin: 1rem 0;
  }

  button {
    width: auto;
  }
}
