@use "../../../styles/abstracts/variables" as *;
@use "../../../styles/abstracts/mixins" as *;

.giftTransfer {
  margin-top: 40px;
  height: 70vh;
  position: relative;
  &[data-size="true"] {
    margin-top: 10px;
  }

  &__loading {
    height: 50vh;
    width: 100%;
    @include flex-row;
  }

  &__data {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1rem;
  }

  &__navbar {
    margin-bottom: 40px;

    & h4 {
      margin-bottom: 8px;
      color: $col-black;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.1875rem;
      text-align: left;
    }

    &-container {
      border: 1px solid $col-secondary;
      height: 51px;
      display: flex;
      align-items: center;
      width: fit-content;
      padding: 0px 32px;
      & button {
        margin-right: 64px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  @include respond(phone) {
    width: 100%;
  }
  &__alert {
    @include flex-row;
    width: 100%;
    margin-bottom: 10px;
    &-container {
      width: 869px;
      height: 73px;
      background: $col-secondary-23;
      border: 1px dashed $col-secondary-24;
      @include border-radius(2px);
      @include flex-row;
      &-box {
        @include grid-column(2, auto);
        grid-column-gap: 10px;
        @include grid-center;
        font-style: normal;
        font-weight: normal;
        font-size: 1.125rem;
        line-height: 1.5625rem;
        color: $col-secondary-25;
        & button {
          font-style: normal;
          font-weight: 600;
          margin-left: 5px;
          font-size: 1.125rem;
          line-height: 1.5625rem;
          color: $col-secondary-25;
          text-decoration: underline;
        }
      }
    }
  }
  &__main {
    height: 100%;
    padding-bottom: 40px;
    &-content {
      height: 100%;
    }
  }
  &__buttons {
    @include grid-column(2, 155px);
    grid-column-gap: 8px;
    margin-top: 8px;
    @include respond(phone) {
      @include grid-column(2, 1fr);
    }
    &-send {
      background-color: $col-secondary-10;
      box-shadow: inset 0px -6px 10px $col-secondary-21;
      color: $col-white;
    }
    &-withdraw {
      background-color: $col-secondary-17;
      border: 1px solid $col-secondary-22;
      color: $col-secondary-20;
    }
    & button {
      height: 43px;
      width: 155px;
      @include border-radius(4px);
      padding: 12px, 24px, 12px, 24px;
      font-style: normal;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.1875rem;
      text-align: right;
      text-transform: capitalize;
      @include flex-row;
      @include respond(phone) {
        width: 100%;
      }
    }
  }
  &-section-heading {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $col-black;
    font-style: normal;
    margin-top: 40px;
    margin-bottom: 16px;
  }
  &__heading {
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375rem;
    text-align: left;
    margin-bottom: 8px;

    @include respond(phone) {
      display: none;
    }
  }
  &__summary-card {
    background-color: $col-white;
    border: 1px solid $col-secondary;
    height: 222px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 326px;
    padding: 24px;
    @include border-radius(5px);
    @include respond(phone) {
      width: 100%;
    }
    &-amount {
      font-size: 2.25rem;
      line-height: 3.0625rem;
      color: $col-black;
      font-style: normal;
      font-weight: bold;
    }
    &-footer {
      @include grid-row(2, auto);
      grid-row-gap: 8px;
      &-link {
        &-icon {
          @include flex-row;
        }
        height: 47px;
        width: 100%;
        @include border-radius(4px);
        display: grid;
        grid-template-columns: 1fr 0.3fr;
        background-color: $col-secondary-2;
        &-text {
          font-size: 1rem;
          font-style: normal;
          font-weight: 700;
          line-height: 1.375rem;
          text-align: center;
          width: 100%;
          @include flex-row;
          text-transform: uppercase;
        }
      }
      &-title {
        font-weight: normal;
        font-style: normal;
        color: $col-black;
        font-size: 1rem;
        line-height: 1.375rem;
        margin-bottom: 8px;
      }
    }
  }

  &__table {
    display: grid;
    grid-template-rows: 45px 1fr;
    grid-row-gap: 10px;

    @include respond(phone) {
      display: none;
    }

    &__list-item-container {
      @include grid-row(auto-fill, auto);
      grid-row-gap: 9px;
    }
    &-header {
      display: grid;
      grid-template-columns: 2fr 1.3fr 1fr 1fr 1fr;
      width: 100%;
      padding: 0px 40px;
      height: 45px;
      align-items: center;
      background-color: $col-secondary-2;
      &-amount {
        & h4 {
          text-align: right;
        }
      }
      & h4 {
        color: $col-secondary-9;
        font-weight: 700;
        font-style: normal;
        font-size: 0.75rem;
        line-height: 1.0231rem;
        // text-align: center;
        text-transform: uppercase;
      }
    }
  }
}

.emptyState {
  width: 100%;
  height: 100%;
  @include flex-row;
  &__container {
    @include grid-row(2, auto);
    grid-row-gap: 16px;
    @include grid-center;
  }
  &__icon {
    font-size: 70px;
  }
  &__title {
    & p {
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5625rem;
      text-align: center;
    }
  }
}
