@use "../../styles/abstracts/variables.scss" as *;
@use "../../styles/abstracts/mixins" as *;

.rsvp {
  background-color: transparent;
  // height: 70vh;
  position: relative;
  padding: 0 30px;

  @include respond(phone) {
    padding: 0;
  }

  &__tabs {
    height: auto;
    border-bottom: 1px solid $col-secondary-7;
    padding-bottom: -10px;
    padding: 1.2rem 1.2rem 0;
  }

  &__main {
    margin-top: 2rem;
    padding: 0 1.2rem;
    height: 100%;
  }

  &__loading {
    height: 50vh;
    width: 100%;
    @include flex-row;
  }
}
