@use "src/styles/abstracts/mixins" as *;
@use "src/styles/abstracts/variables" as *;

.invitations {
  padding: 0 30px;

  @include respond(phone) {
    padding: 0;
  }

  &__tabs {
    height: auto;
    border-bottom: 1px solid $col-secondary-7;
    padding: 1.2rem 0 0;
    overflow-y: hidden;

    @include respond(phone) {
      padding: 1.2rem 1.2rem 0;
    }
  }
  &__main {
    margin-top: 2rem;
  }
}
