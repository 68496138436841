// Abstract
@use "./abstracts/mixins" as *;
@use "./abstracts/tokens";
@use "./abstracts/variables" as *;

//Base
@use "./base/reset";

//Fonts
@use "./fonts/typography";

@tailwind base;
@tailwind components;
@tailwind utilities;

.toast {
  .bold {
    font-weight: 700;
  }
}

div[data-radix-portal] {
  z-index: 2147483646 !important;
}

.react-tel-input {
  font-family: "Inter", sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.react-tel-input .flag-dropdown {
  background-color: #fafafa !important;
  border: 1px solid #f2f2f2 !important;
  border-radius: 2px 0 0 2px !important;
}

.react-tel-input .form-control {
  color: $col-secondary-13 !important;
  background-color: #fafafa !important;
  border: 1px solid #f2f2f2 !important;
  border-radius: 2px !important;
}

.no-scrollbar {
  scrollbar-width: 0;

  &::-webkit-scrollbar {
    display: none;
  }
}

.svg-full {
  svg {
    width: 100%;
    height: 100%;
  }
}
