@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.removeCategoryModal {
  &--content {
    background-color: $col-white;
    padding: 40px;
    height: auto;
    width: 551px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.5rem;
    height: fit-content;

    @include respond(phone) {
      width: 100vw;
      min-height: 100vh;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: fixed;
      @include z-index(fill-page);
    }
    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      height: 40px;
      margin-bottom: 10px;
    }
    p {
      font-size: 16px;
    }

    &-btnContainer {
      display: flex;
      width: auto;
      margin-top: 0.4rem;
      align-self: flex-end;

      div {
        margin-left: auto;
        button {
          width: auto;
        }
      }
    }
  }
}
