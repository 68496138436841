@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.transactionDetails {
  &_content {
    background-color: $col-white;
    padding: 40px;
    height: auto;
    width: 551px;
    @include column;
    gap: 20px;
    border-radius: 5px;
    max-height: 90vh;
    overflow-y: scroll;

    @include respond(phone) {
      height: 100vh;
      height: -moz-available;
      height: -webkit-fill-available;
      height: calc(100vh - calc(100vh - 100%));
      width: 100vw;
      height: calc(100vh - calc(100vh - 100%));
      width: -moz-available;
      width: -webkit-fill-available;
      @include column;
      gap: 0.75rem;
      @include z-index(fill-page);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: fixed;
    }

    &_header {
      font-weight: 500;
      font-size: 25px;
      color: $col-black;
      line-height: 30px;
      margin-bottom: 0.5rem;
    }

    &_body {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      &_item {
        display: grid;
        align-items: center;
        grid-template-columns: 200px 1fr;

        &_key {
          font-size: 12px;
          line-height: 14.4px;
        }

        &_value {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
