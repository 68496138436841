@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.addPictures {
  background-color: $col-white;
  padding: 40px;
  height: auto;
  width: 551px;
  border-radius: 5px;

  @include respond(phone) {
    border-radius: 0px;
    height: 100vh;
    height: -moz-available;
    height: -webkit-fill-available;
    height: calc(100vh - calc(100vh - 100%));
    width: 100vw;
    width: -moz-available;
    width: -webkit-fill-available;
    @include z-index(fill-page);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  &-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    width: 100%;
  }
  &-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;

    &::before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      border-radius: 50%;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
  &-input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .addPictures-slider {
      background-color: #00ae07;
    }

    &:focus + .addPictures-slider {
      box-shadow: 0 0 1px #00ae07;
    }

    &:checked + .addPictures-slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }

  &-image {
    @include grid-row(2, auto);
    grid-row-gap: 8px;
    &-box {
      width: 100%;
      height: 466px;
      background-color: $col-secondary-2;
      border: 1px solid $col-secondary-2;
      border-radius: 30px;
      overflow: hidden;
      @include flex-row;
    }
    &-box2 {
      width: 100%;
      height: 225;
      background-color: $col-secondary-2;
      border: 1px solid $col-secondary-2;
      border-radius: 30px;
      overflow: hidden;
      @include flex-row;
    }
  }

  &-form {
    &-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 1rem;

      &-tags {
        width: 100%;
      }
    }
  }

  &-heading {
    margin-bottom: 40px;
    height: auto;
    & button {
      display: none;
      @include respond(phone) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
    & h4 {
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: 2.0625rem;
      text-align: left;
    }
    & p {
      @include respond(phone) {
        display: none;
      }
    }
  }
  &-file-picker {
    &-box {
      width: fit-content;
      height: 46px;
      padding: 0px 24px;
      @include border-radius(4px);
      background-color: #eff5f4;
      @include flex-row;
    }
  }

  // &-upload-button {
  //   & button {
  //     background-color: #eff5f4;
  //     height: 46px;
  //     width: fit-content;
  //     & span {
  //       color: $col-primary;
  //     }
  //   }
  // }

  &-button {
    width: fit-content;
    &-container {
      width: 100%;
      @include flex-space-between;
      justify-content: center;
      height: 70px;
      margin-top: 40px;
      gap: 10px;
    }
  }
}

.file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  & + label {
    display: inline-block;
    color: $col-primary;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.375rem;
    text-align: center;
  }
}

.file + label {
  cursor: pointer;
}
