@use "src/styles/abstracts/mixins" as *;
@use "src/styles/abstracts/variables" as *;

.templateCard {
  &__icon {
    display: block;

    @include respond(phone) {
      display: none;
    }
  }

  &__mobile {
    &-views {
      @include flex-row;

      &-box {
        @include grid-column(2, auto);
        grid-column-gap: 12px;
      }

      &-orientation {
        background: #34544f;
        border-radius: 3px;
        width: 52px;
        height: 52px;
        border: 1px solid #34544f;

        @include border-radius(4px);
        @include flex-row;
        @include transition;

        &__active {
          background: #273f3b;
          border: 1px solid #4e7e77;
          border-radius: 4px;
        }
      }
    }

    &-preview {
      max-height: unset;
      height: 100%;
      width: 100%;
      border-radius: 0px;
      padding: 2rem;
      background-color: #34544f;
      gap: 2rem;

      @include column;

      &-box {
        width: 100%;
        height: auto;
        margin-top: 1rem;
        background-color: $col-white;
        position: relative;

        &__showcase {
          width: 100% !important;
          height: auto !important;
          position: absolute;
          top: 0;
        }

        &__landscape {
          height: 75vh;
          width: 80vw;
        }

        &__portrait {
          height: 75vh;
          width: 80vw;
        }

        &-container {
          width: 80vw;
          height: 75vh;
          overflow-y: auto;
          margin: 0 auto;

          @include flex-row;
        }
      }

      &-title {
        color: $col-white;
      }
    }
  }

  &__preview {
    width: 89px;
    height: 35px;
    display: none;

    @include respond(phone) {
      display: block;
    }

    &-button {
      width: 100%;
      height: 100%;
      background-color: $col-primary;
      @include border-radius(4px);
      @include row;
      align-items: center;
      padding: 8px;
      & p {
        margin-right: 9px;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.1875rem;
        text-align: left;
        color: $col-white;
      }
      & span {
        @include row;
        align-items: center;
      }
    }
  }

  &__container {
    display: grid;
    grid-template-columns: auto 1fr 15px;
    grid-column-gap: 16px;
    width: 100%;
    align-items: center;
    @include respond(phone) {
      grid-template-columns: 55px 1fr 89px;
    }
  }

  &__image {
    height: 55px;
    width: 55px;
    @include border-radius(4px);
    background-image: url(../../../../assets/images/guest.png);
    background-position: top center;
    background-size: contain;

    & img {
      min-width: 100%;
      height: 100%;
    }
  }
  &__heading {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    text-align: left;
    color: $col-black;
  }

  width: 100%;
  height: 75px;
  background-color: $col-secondary-29;
  box-shadow: inset 4px 0px 0px $col-primary;
  border-left: 3px solid $col-primary;
  @include flex-row;
  padding: 10px 40px 10px 40px;
  @include respond(phone) {
    border-left: 0px;
    box-shadow: none;
    padding: 0px;
    background-color: transparent;
  }
}
