@use "../../styles/abstracts/variables.scss" as *;
@use "../../styles/abstracts/mixins" as *;

.dashboardLayout {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  &__float {
    width: 45px;
    height: 45px;
    position: fixed;
    z-index: 800;
    border-radius: 50%;
    display: none;
    place-items: center;
    bottom: 2.25rem;
    left: 1.5rem;
    background: $col-secondary-4;
    box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
      0px 0px 1px rgba(9, 30, 66, 0.31);

    svg {
      path {
        fill: $col-white;
      }
    }

    @include respond(tab) {
      display: grid;
    }
  }

  &__preview {
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    @include flex-row;
    padding: 0 10px;
    width: 40px;
    height: 40px;
    margin: auto;
    @include transition;

    &:hover {
      background-color: $col-secondary-40;
    }

    path {
      stroke-width: 1.5px;
    }
  }

  &__loading {
    width: 100%;
    height: 100%;
    @include flex-row;

    & div {
      width: 30px;
      height: 30px;
    }
  }

  &__sidebar {
    height: 100vh;
    height: -moz-available;
    height: -webkit-fill-available;
    height: calc(100vh - calc(100vh - 100%));
    width: 100vw;
    width: -moz-available;
    width: -webkit-fill-available;
    overflow-y: auto;
    top: 0;
    bottom: 0;
    position: relative;
    width: 300px;
    background-color: $col-secondary-2;
    padding: 8px 0px 0px 0px;
    @include transition;
    opacity: 1;
    z-index: 999;

    &-close {
      background-color: transparent;
    }

    @include respond(tab) {
      padding: 32px 0px 0px 0px;
      position: fixed;
      // opacity: 0;
      top: 0;
      bottom: 0;
      width: 100%;
    }

    &--mobile {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 0 24px;

      &-logo {
        margin: 0.5rem auto;
        display: none;
      }

      &-close {
        margin-left: auto;
        display: none;
      }

      button {
        border: 1px solid $col-secondary-8;
        border-radius: 8px;
        background-color: transparent;
        box-shadow: none;
        display: none;
        margin-top: 1.4rem;
        span {
          color: $col-black;
        }
      }
      @include respond(tab) {
        &-close {
          display: block;
          cursor: pointer;
        }
        &-logo {
          display: block;
        }
        button {
          display: block;
          width: 100%;
        }
      }
      .dropdown {
        background-color: $col-secondary-26;
        padding: 10px;
        opacity: 0;
        position: absolute;
        width: 100%;
        top: 95px;
        z-index: 1;
        display: none;

        &-item {
          width: 100%;

          button {
            background-color: $col-primary;
            width: 100%;
            min-width: 181px;

            span {
              color: #fff;
            }
          }

          &--content {
            min-height: 120px;
            overflow: scroll;
            display: flex;
            justify-content: center;
            flex-direction: column;

            li {
              margin-bottom: 5px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    &--links {
      &__preferences {
        &__title {
          padding-top: 1rem;
          color: #000000;
        }
      }

      padding: 1.5rem 20px 20px;
      text-transform: capitalize;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;

      &-logo {
        cursor: pointer;
        @include flex-row;
        justify-content: flex-start;
        margin: 0 0 0.9rem 0.75rem;

        @include respond(tab) {
          display: none;
        }
      }

      a {
        margin: 12px 8px;
        width: 100%;

        @include respond(mini-laptop) {
          margin: 8px 0;
        }
      }

      li {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 8px 12px;
        color: $col-secondary-9;
        border-radius: 4px;

        @include transition;

        &:hover {
          color: $col-primary;
          background-color: $col-secondary-40;
        }

        @include respond(mini-laptop) {
          padding: 8px 12px;
        }

        span {
          margin-left: 20px;

          &.active {
            color: $col-primary;
            font-weight: 600;
          }
        }
      }
    }
  }

  &__main {
    position: relative;
    width: 100%;
    height: 100%;
    margin-left: auto;
    overflow-y: auto;
    overflow-x: hidden;

    @include respond(phone) {
      width: 100%;
      margin: auto;
      padding: 0px;
    }

    &--header {
      width: 100%;
      height: auto;
      padding: 1.2rem;
      @include flex-row-custom(space-between);
      box-shadow: 0px 1px 0px rgba(179, 179, 179, 0.25);

      @include respond(phone) {
        padding: 0.75rem 1.2rem;
        width: 100%;
        box-shadow: 0px 1px 0px rgba(167, 167, 167, 0.25);
        justify-content: flex-start;
      }

      h3 {
        font-size: 24px;
        font-weight: 600;

        @include respond(tab) {
          font-size: 18px;
        }
      }

      &-hamburger {
        display: none;
        width: fit-content;
        height: 32px;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
        cursor: pointer;

        &:hover {
          div {
            @include transition;

            &::after {
              transform: translateY(2px);
            }
            &::before {
              transform: translateY(-2px);
            }
          }
        }

        div {
          position: relative;
          width: 1.5rem;
          border-radius: 4px;
          height: 2px;
          background-color: $col-white;
          @include transition;

          &:focus {
            outline: 1px dotted transparent;
            outline-offset: 1px;
          }

          &:focus-visible {
            outline: 1px dotted $col-secondary-8;
            outline-offset: 1px;
          }

          @include transition;

          &::before {
            @include transition;

            will-change: transform;
            cursor: pointer;
            width: 1.5rem;
            border-radius: 4px;
            height: 2px;
            background-color: $col-white;
            position: absolute;
            content: "";
            top: -0.5rem;
          }

          &::after {
            @include transition;

            will-change: transform;
            cursor: pointer;
            width: 1.5rem;
            border-radius: 4px;
            height: 2px;
            background-color: $col-white;
            position: absolute;
            content: "";
            bottom: -0.5rem;
          }
        }

        &__other {
          div {
            background-color: $col-black;
            &::after {
              background-color: $col-black;
            }
            &::before {
              background-color: $col-black;
            }
          }
        }

        @include respond(tab) {
          display: flex;
        }
      }

      &-wrapper {
        display: flex;
        margin-left: auto;
        gap: 1rem;

        button {
          &:first-child {
            width: 100%;
            display: grid;
          }
        }

        div {
          button {
            width: auto;
            border: 1px solid $col-black;
            background: $col-white;
            border-radius: 4px;
            box-shadow: none;

            &:hover {
              background: $col-white;
            }

            span {
              width: 100%;
              color: $col-black;
              @include flex-row;
              justify-content: space-between;
            }

            @include respond(phone) {
              display: none;
            }
          }
        }
      }
    }
    &--body {
      margin-top: 15px;
      @include respond(phone) {
        padding: 1.2rem;
      }
    }

    &__event-title {
      @include flex-row;
      border-radius: 8px;
      border: 1px solid $col-secondary-2;
      background-color: $col-secondary-2;
      padding: 10px 16px;
      font-weight: 700;
      max-height: 50px;
      font-size: 14px;
      line-height: 20px;
      gap: 0.75rem;
      min-width: 100%;
      text-transform: capitalize;
      width: 250px;
      line-height: 19px;
      color: $col-black;
      @include flex-space-between;
      overflow: hidden;

      @include transition;

      &:hover {
        background-color: $col-secondary-40;
      }

      @include respond(tab) {
        display: none;
      }

      &__aside {
        @include respond(tab) {
          display: flex;
        }
      }

      p {
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
      }

      &__other {
        background-color: $col-white;
        border: 1px solid $col-secondary-3;
        overflow: hidden;

        @include transition;

        &:hover {
          background-color: $col-secondary-40;
        }

        @include respond(phone) {
          display: none;
        }

        p {
          width: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    &__fixed-list {
      margin-top: -1rem !important;
    }

    &__event-list {
      background: $col-white;
      box-shadow: 0px 1px 1px rgba(187, 187, 187, 0.25),
        0px 0px 14px rgba(169, 169, 169, 0.25);
      border-radius: 8px;
      width: 250px;
      margin-top: 1rem;

      @include respond(tab) {
        width: 93.5vw;
        // margin-left: 1rem;
      }

      @include floating-transition;

      .dropdown-item--content {
        max-height: 300px;
        overflow-y: scroll;
        position: relative;
        border-radius: 8px;

        & li {
          border-bottom: 1px solid $col-secondary;
          padding: 16px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $col-secondary-37;
          cursor: pointer;

          @include transition;

          &:hover,
          &:focus {
            background: $col-secondary;
          }

          button {
            border: none;
            padding: 0;
            box-shadow: none;
            background: transparent;
            height: auto;
            border-radius: 8px;
            justify-content: flex-start;

            span {
              font-weight: 400;
              font-size: 16px;
              line-height: 18px;
              color: $col-fail;

              @include flex-row;
              justify-content: flex-start;
              gap: 0.5rem;
            }

            &:hover,
            &:focus,
            &:active {
              background: transparent;

              span {
                color: $col-fail;
              }
            }
          }
        }

        & li.dropdown-item--list--first {
          border-radius: 8px 8px 0 0;
          border-bottom: 1px solid #f2f2f2;
          background: #fafafa;
          font-weight: 700;
          justify-content: space-between;
          display: flex;
          align-items: center;
          cursor: default;
          overflow: hidden;

          p {
            width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        & li.dropdown-item--list--last {
          border-radius: 0 0 8px 8px;
          border: none;
        }
      }
    }

    &__img-bubble {
      background: $col-secondary-34;
      border: 0.5px solid $col-secondary-4;
      border-radius: 50%;
      display: grid;
      place-items: center;
    }

    &__bubble {
      background: $col-white;
      border: 1px solid $col-secondary-8;
      border-radius: 5px;
      box-sizing: border-box;
      width: fit-content;
      padding: 0.25rem 0.5rem;
      height: 40px;
      color: #000;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;

      @include transition;

      &:hover {
        background-color: $col-secondary-40;
      }

      &__img {
        border-radius: 50%;
        box-sizing: border-box;
        padding: 0.25rem;
        width: 40px;
        overflow: hidden;
        object-fit: cover;
        object-position: center;
        height: 40px;

        &__container {
          width: fit-content;
          height: fit-content;
        }
      }

      &__other {
        background: $col-white;
        white-space: nowrap;

        @include transition;

        &:hover {
          background-color: $col-secondary-40;
        }
      }

      &-avatar {
        border-radius: 50%;
        box-sizing: border-box;
        color: $col-black;
        padding: 0.25rem;
        width: 40px;
        overflow: hidden;
        height: 40px;
      }

      &-content {
        width: 100%;
        max-width: 270px;
      }

      &-fill {
        fill: $col-white;
        color: $col-white;
      }

      &-content {
        background: $col-white;
        border-radius: 4px;
        padding: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px;

        @include floating-transition;
      }
    }

    &__team-content {
      margin-top: 1rem;
      width: 390px;
      background: $col-white;
      box-shadow: 0px 1px 1px rgba(187, 187, 187, 0.25),
        0px 0px 14px rgba(169, 169, 169, 0.25);
      border-radius: 4px;
      padding: 24px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      @include floating-transition;

      @include respond(phone) {
        width: 100%;
      }

      button {
        border: 1px solid $col-black;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 12px 16px;
        box-shadow: none;
        background: $col-white;

        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 18px;
          color: $col-black;
        }

        &:hover,
        &:active {
          background: $col-primary;

          span {
            color: $col-white;
          }
        }

        &:focus {
          outline: 1px dotted transparent;
        }

        &:focus-visible {
          outline: 1px dotted $col-secondary-8;
        }
      }

      h4 {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 24px;
      }

      &__body {
        margin-bottom: 24px;

        &-group {
          @include flex-row;

          justify-content: space-between;
          gap: 0.75rem;
          margin-bottom: 24px;
          width: 100%;

          button {
            width: auto;
          }

          &__btn {
            border: 1px solid $col-secondary-35;
            box-sizing: border-box;
            border-radius: 2px;
            padding: 8px 12px;
            max-width: 75px;
            height: auto;

            @include transition;

            &:hover {
              background: $col-secondary-8;

              span {
                color: $col-black;
              }
            }

            &:focus {
              background: $col-white;
              outline: 1px dotted transparent;
            }

            &:focus-visible {
              background: $col-white;
              outline: 1px dotted $col-secondary-8;
            }

            span {
              font-weight: 400;
              color: $col-black;
              font-size: 14px;
              line-height: 16px;
            }
          }

          &__btn2 {
            width: 100%;
            height: 46px;
            background-color: $col-white;
            padding: 0px 24px;
            border: 1px solid #bfbfbf;
            box-sizing: border-box;
            border-radius: 4px;
            @include flex-row;
            @include border-radius(4px);
            @include transition;

            & span {
              color: $col-black;
              font-style: normal;
              font-weight: 600;
              font-size: 1rem;
              line-height: 1.375rem;
            }

            &:focus {
              outline: 1px dotted transparent;
              outline-offset: 1px;
            }

            &:focus-visible {
              outline: 1px dotted $col-black;
              outline-offset: 1px;
            }

            &:disabled,
            &[disabled] {
              @include border-radius(4px);
              background-color: $col-secondary-8;
              box-shadow: none;
              filter: none;

              &:hover,
              &:focus-within {
                background-color: $col-secondary-8;
                cursor: not-allowed;
              }
            }

            &:hover,
            &:focus-within {
              border: 1px solid $col-primary;
              background-color: $col-primary;
            }

            &:active {
              border: 1px solid $col-primary;
              background-color: $col-primary;
            }
          }

          &__details {
            h5 {
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
            }

            p {
              text-transform: capitalize;
              font-weight: 400;
              font-size: 14px;
              line-height: 19px;
              color: $col-secondary-9;
            }
          }
        }
      }
    }
  }

  &__popover {
    &:focus {
      outline: 1px dotted transparent;
    }

    &:focus-visible {
      outline: 1px dotted $col-secondary-8;
    }
  }
}
