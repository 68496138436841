@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  width: 100%;

  &__inner {
    max-width: 380px;
    margin: 0.2rem;
    min-width: 280px;
  }
}

.count {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.4rem;
  gap: 8px;
  border: 1px solid #e4e4e7;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  color: #141414;
}

.coming {
  width: 100%;
  position: relative;

  &__table {
    @include column;
    &-header {
      @include flex-row;
      justify-content: flex-start;
      padding: 16px 40px;
      width: 100%;
      height: 45px;
      align-items: center;
      // gap: 0.75rem;

      & h4 {
        font-style: normal;
        text-align: left;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        text-align: left;
        min-width: 180px;
        width: 20%;
        color: #808080;
      }
    }
  }

  &__send {
    @include row;
    justify-self: flex-end;
    align-items: center;

    &-toggle {
      height: 20px;
      width: 44px;
      margin-left: 16px;
      @include border-radius(100px);
      background-color: $col-secondary;
      position: relative;

      &[aria-checked="true"] {
        background-color: #1fad3e;
      }

      &-knob {
        height: 22px;
        width: 22px;
        @include border-radius(100px);
        background-color: $col-white;
        box-shadow: 0px 4px 4px rgba(137, 137, 137, 0.25);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15),
          0px 3px 1px rgba(0, 0, 0, 0.06);
        @include transition;

        &[data-checked="true"] {
          transform: translateX(calc(100%));
        }
      }
    }

    & h4 {
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.375rem;
      text-align: left;
      color: $col-black;
    }
  }
  &__header {
    @include flex-space-between;
    width: 100%;

    &--search {
      width: 431px;

      &-container {
        @include row;
        align-items: center;
      }
    }
    &--btn {
      margin-left: 20px;
      width: auto;

      button {
        box-shadow: none;
        border: 1px solid black;
        background-color: transparent;
        span {
          color: black;
        }
        svg {
          transform: rotate(-90deg);
          margin-left: 5px;
        }
      }
      .actionlist {
        background-color: $col-white;
        position: absolute;
        height: auto;
        width: 138px;
        @include border-radius(4px);
        box-shadow: 0px 0px 2px rgba(125, 125, 125, 0.2),
          0px 2px 10px rgba(0, 0, 0, 0.1);

        li {
          cursor: pointer;
          padding: 0.7rem 1rem;
          &:hover {
            background-color: $col-secondary-2;
          }
        }
      }
    }

    @include respond(phone) {
      &--search {
        width: 100%;
      }
      &--btn {
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }

  &__main {
    margin-top: 2rem;
    width: 100%;
    overflow-x: auto;

    &--pagination {
      padding: 10px;
      background-color: $col-secondary;
      min-height: 45px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 13rem;
      width: 100%;
      font-size: 12px;
      position: absolute;
      top: 100%;
      left: 0;

      @include respond(mini-laptop) {
        padding-right: 6rem;
      }

      @include respond(tab) {
        padding-right: 1rem;
      }

      @include respond(phone) {
        padding-right: 10px;
      }

      section {
        font-weight: 600;
        @include flex-row;
        &:first-child {
          svg {
            margin-left: 5px;
            cursor: pointer;
          }
        }
        &:last-child {
          @include flex-row;
          margin-left: 4rem;
          padding-top: 5px;
          height: auto;
          flex-wrap: wrap;
          @include respond(phone) {
            justify-content: flex-end;
          }

          div {
            margin-left: 1rem;
            cursor: pointer;
            .rotate {
              margin-left: 50px;
              svg {
                transform: rotate(180deg);
              }
            }
            @include respond(phone) {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
  &__loading {
    height: 50vh;
    width: 100%;
    @include flex-row;
  }
}
