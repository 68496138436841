@use "../../../../styles/abstracts/variables" as *;
@use "../../../../styles/abstracts/mixins" as *;

.wishlist__addItem {
  background-color: $col-white;
  padding: 40px;
  height: auto;
  width: 551px;
  display: grid;
  grid-template-rows: 60px 1fr;
  grid-row-gap: 40px;
  border-radius: 5px;

  @include respond(phone) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    height: -moz-available;
    height: -webkit-fill-available;
    height: calc(100vh - calc(100vh - 100%));
    width: 100vw;
    width: -moz-available;
    width: -webkit-fill-available;
    position: fixed;
  }

  &-image {
    @include grid-row(2, auto);
    grid-row-gap: 8px;
    &-box {
      width: 100%;
      height: 149px;
      background-color: $col-secondary-2;
      border: 1px solid $col-secondary-2;
      @include flex-row;
      & div {
        width: 70px;
        height: 70px;
      }
    }
  }

  &-heading {
    @include grid-row(2, auto);
    grid-row-gap: 8px;
    & button {
      display: none;
      @include respond(phone) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
    & h4 {
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;
      text-align: left;
    }
    & p {
      color: #808080;

      @include respond(phone) {
        display: none;
      }
    }
  }
  &-form {
    &-container {
      @include grid-row(auto-fill, auto);
      grid-row-gap: 24px;
    }
  }
  &-file-picker {
    &-box {
      width: fit-content;
      height: 46px;
      padding: 0px 24px;
      @include border-radius(4px);
      background-color: #eff5f4;
      @include flex-row;
    }
  }
  &-button {
    width: fit-content;

    &-container {
      width: 100%;

      @include flex-space-between;

      justify-content: flex-end;
      margin-top: 24px;

      & button {
        width: fit-content !important;
      }
    }
  }
}

.file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  & + label {
    display: inline-block;
    color: $col-primary;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.375rem;
    text-align: center;
  }
}

.file + label {
  cursor: pointer;
}
