@use "../../styles/abstracts/mixins" as *;
@use "../../styles/abstracts/variables" as *;

.gifts {
  padding: 0 30px;

  @include respond(phone) {
    padding: 0;
  }

  &__button {
    @include grid-column(2, auto);
    grid-column-gap: 16px;
    align-items: center;

    @include respond(mini-laptop) {
      display: none;
    }
    &-preview {
      width: fit-content;
    }
  }
  &__heading-container {
    @include flex-space-between;
    margin-bottom: 45px;
    @include respond(phone) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    & h5 {
      font-weight: 600;
      font-style: normal;
      font-size: 1.5rem;
      line-height: 2.0463rem;
      color: $col-black;
      @include respond(phone) {
        margin-left: 20px;
      }
    }
  }
  &__dropdown {
    &-container {
      position: relative;
      &-button {
        background-color: $col-white;
        border: 1px solid $col-secondary-8;
        @include border-radius(4px);
        padding: 0px 24px;
        width: fit-content;
        height: 43px;
        @include row;
        align-items: center;
        color: $col-black;
        font-weight: 600;
        font-style: normal;
        font-size: 0.875rem;
        line-height: 1.1875rem;
      }
    }
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    @include z-index("dropdown-menu");
    background-color: $col-white;
    box-shadow: 1px 2px 6px rgba(51, 51, 51, 0.15);
    @include border-radius(4px);
    width: 150px;
    height: auto;
    padding: 20px 32px 20px 20px;
    margin-top: 10px;
    &[aria-expanded="true"] {
      @include grid-center;
    }

    &-icon {
      margin-left: 8px;
    }
  }
  &__tab-container {
    border-bottom: 1px solid $col-secondary-7;
    width: 100%;
    @include row;
    align-items: center;
    padding: 1.2rem 1.2rem 0;
  }
}
