@use "../../../../styles/abstracts/variables" as *;
@use "../../../../styles/abstracts/mixins" as *;

.viewMessage {
  background-color: $col-white;
  padding: 40px;
  height: auto;
  width: 551px;
  display: grid;
  grid-template-rows: 60px 1fr;
  grid-row-gap: 40px;
  border-radius: 5px;

  @include respond(phone) {
    border-radius: 0px;
    height: 100vh;
    height: -moz-available;
    height: -webkit-fill-available;
    height: calc(100vh - calc(100vh - 100%));
    width: 100vw;
    width: -moz-available;
    width: -webkit-fill-available;
    @include z-index(fill-page);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &-card {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0px 24px;
    background-color: #fafafa;
    border-left: 3px solid $col-black;
    height: 92px;
    & p {
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.375rem;
      text-align: left;
    }
  }

  &-heading {
    & button {
      display: none;
      @include respond(phone) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
    & h4 {
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: 2.0625rem;
      text-align: left;
    }
    & p {
      @include respond(phone) {
        display: none;
      }
    }
  }
  &-form {
    &-container {
      @include grid-row(auto-fill, auto);
      grid-row-gap: 24px;
    }
  }
  &-button {
    margin-top: 40px;
    width: 165px;

    &__trigger {
      width: 100%;
      height: 46px;
      background-color: $col-primary;
      padding: 0px 24px;
      box-shadow: inset 0px -6px 10px #314f4a;
      @include flex-row;
      @include border-radius(4px);
      @include transition;

      & span {
        color: $col-white !important;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.375rem;
      }

      &:focus {
        outline: 1px dotted transparent;
        outline-offset: 1px;

        & span {
          color: $col-white;
        }
      }

      &:focus-visible {
        outline: 1px dotted $col-secondary-8;
        outline-offset: 1px;

        & span {
          color: $col-white;
        }
      }

      &:disabled,
      &[disabled] {
        @include border-radius(4px);
        background-color: $col-secondary-8;
        box-shadow: none;
        filter: none;

        &:hover,
        &:focus-within {
          background-color: $col-secondary-8;
          cursor: not-allowed;

          & span {
            color: $col-white;
          }
        }
      }

      &:hover,
      &:focus-within {
        background-color: $col-secondary-4;

        & span {
          color: $col-white;
        }
      }

      &:active {
        background-color: $col-primary;
      }
    }

    &-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}
