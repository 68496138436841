@use "../../../styles/abstracts/variables" as *;
@use "../../../styles/abstracts/mixins" as *;

.wishlist {
  margin-top: 40px;
  width: 100%;
  height: 70vh;
  position: relative;
  padding: 0 1.2rem;

  &__modal {
    position: relative;
  }

  &__header {
    @include flex-space-between;
    flex-wrap: wrap;
    gap: 1rem;

    &-shape {
      border: 1px solid #bfbfbf;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 14px;
      width: 46px;
      height: 46px;
      display: grid;
      place-items: center;

      @include transition;

      &:focus {
        outline: 1px dotted transparent;
        outline-offset: 0.5px;
      }

      &:focus-visible {
        outline: 1px dotted $col-secondary-5;
        outline-offset: 0.5px;
      }

      &__active {
        border: 1px solid #34544f;
      }
    }

    &-search {
      max-width: 431px;
      width: 100%;
      height: 48px !important;

      & > div {
        height: 48px !important;
      }

      input {
        padding-left: 48px !important;
        height: 48px !important;
      }
    }
    &-addItem {
      width: fit-content;

      &:focus {
        outline: 1px dotted transparent;
        outline-offset: 0.5px;
      }

      &:focus-visible {
        outline: 1px dotted $col-secondary-5;
        outline-offset: 0.5px;
      }
    }
    &-editItem {
      display: none;
      @include respond(phone) {
        display: block;
      }
      & button {
        width: 106px;
        height: 43px;
        @include border-radius(4px);
        padding: 12px 24px;
        @include flex-row;
        background-color: $col-white;
        border: 1px solid $col-black;
      }
    }
    &-buttons {
      @include flex-row;
      gap: 8px;
    }
  }
  &__table {
    display: grid;
    grid-template-rows: 45px 1fr;
    grid-row-gap: 10px;
    &__list-item-container {
      @include grid-row(auto-fill, auto);
      grid-row-gap: 9px;
    }
    &-header {
      display: grid;
      grid-template-columns: 2fr 2fr 1fr 70px;
      width: 100%;
      padding: 0px 40px;
      height: 45px;
      align-items: center;
      background-color: $col-secondary-2;

      &-price,
      &-action {
        width: 100%;
        & h4 {
          color: $col-secondary-9;
          font-weight: 700;
          font-style: normal;
          font-size: 0.75rem;
          line-height: 1.0231rem;
          text-transform: uppercase;
        }
      }
      &-item {
        width: 200px;
        @include flex-row;
        & h4 {
          color: $col-secondary-9;
          font-weight: 700;
          font-style: normal;
          font-size: 0.75rem;
          line-height: 1.0231rem;
          text-align: center;
          text-transform: uppercase;
        }
      }
    }
  }
  &__dropdown {
    &-container {
      position: relative;

      @include respond(mini-laptop) {
        display: none;
      }
      &-button {
        background-color: $col-white;
        border: 1px solid $col-secondary-8;
        @include border-radius(4px);
        padding: 0px 24px;
        width: fit-content;
        height: 43px;
        @include row;
        align-items: center;
        color: $col-black;
        font-weight: 600;
        font-style: normal;
        font-size: 0.875rem;
        line-height: 1.1875rem;
      }
    }
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    @include z-index("dropdown-menu");
    background-color: $col-white;
    box-shadow: 1px 2px 6px rgba(51, 51, 51, 0.15);
    @include border-radius(4px);
    width: 150px;
    height: auto;
    padding: 20px 32px 20px 20px;
    margin-top: 10px;
    &[aria-expanded="true"] {
      @include grid-center;
    }
    &-list {
      @include grid-row(2, auto);
      grid-row-gap: 16px;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
    }

    &-icon {
      margin-left: 8px;
    }
  }

  &__main {
    margin-top: 24px;
    padding-bottom: 40px;
    overflow-x: auto;

    &__top {
      @include flex-row;
      justify-content: space-between;
      padding: 0 40px 16px 40px;
      width: 100%;
      height: 45px;
      align-items: center;
      gap: 0.75rem;

      @include respond(tab) {
        justify-content: flex-start;
        padding: 0 16px 0;
      }

      & h4 {
        font-style: normal;
        text-align: left;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        text-align: left;
        min-width: 45px;
        color: #808080;

        @include respond(tab) {
          min-width: 180px;
        }
      }
    }

    &-content {
      height: 100%;

      &-table {
        display: flex;
        flex-direction: column !important;
        height: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
      }

      &-card {
        @include grid-column(auto-fill, 240px);
        @include grid-row(auto-fill, auto);
        grid-row-gap: 24px;
        grid-column-gap: 24px;

        @include respond(phone) {
          @include grid-column(auto-fill, 100%);
        }
      }
    }
  }

  &__loading {
    height: 50vh;
    width: 100%;
    @include flex-row;
  }

  &__loading-mobile {
    display: none;
    @include respond(big-tab) {
      height: 50vh;
      width: 100%;
      @include flex-row;
    }
  }
}

.emptyState {
  width: 90%;
  margin: auto;

  @include respond(phone) {
    width: 100%;
  }

  &--title {
    color: $col-secondary-25;
    font-size: 16px;
    font-weight: normal;
    margin-left: 10px;
    width: 90%;
  }

  &--main {
    @include flex-column;
    margin-top: 4rem;

    p {
      margin-bottom: 1rem;
      margin-top: 1rem;
      font-size: 18px;
      text-align: center;
    }

    button {
      width: auto;
    }

    &__trigger {
      width: 100%;
      height: 46px;
      background-color: $col-primary;
      padding: 0px 24px;
      box-shadow: inset 0px -6px 10px #314f4a;
      @include flex-row;
      @include border-radius(4px);
      @include transition;

      & span {
        color: $col-white !important;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.375rem;
      }

      &:focus {
        outline: 1px dotted transparent;
        outline-offset: 1px;

        & span {
          color: $col-white;
        }
      }

      &:focus-visible {
        outline: 1px dotted $col-secondary-8;
        outline-offset: 1px;

        & span {
          color: $col-white;
        }
      }

      &:disabled,
      &[disabled] {
        @include border-radius(4px);
        background-color: $col-secondary-8;
        box-shadow: none;
        filter: none;

        &:hover,
        &:focus-within {
          background-color: $col-secondary-8;
          cursor: not-allowed;

          & span {
            color: $col-white;
          }
        }
      }

      &:hover,
      &:focus-within {
        background-color: $col-secondary-4;

        & span {
          color: $col-white;
        }
      }

      &:active {
        background-color: $col-primary;
      }
    }
  }
}
