@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.row {
  min-width: fit-content;
  @include flex-row;
  justify-content: flex-start;
  padding: 16px 40px;
  background: $col-secondary-2;
  margin-bottom: 0.75rem;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: $col-black;
    min-width: 180px;
    width: 25%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    text-align: left;
  }
}
