.handsontable {
  font-size: 14px;
}

.handsontable.listbox td.htDimmed {
  padding: 4px;
}

.handsontable thead .relative {
  font-weight: 600;
}

.handsontable tbody tr th .relative {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.handsontable td {
  padding: 12px;
}

.htInvalid {
  border: 1px solid red !important;
  background-color: #ffe6e6 !important;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

.handsontable-container {
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Prevent vertical scrolling (optional) */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for mobile devices */
  width: 100%; /* Full width */
  margin: 0 auto; /* Center the container */
}

.handsontable-container table {
  min-width: auto; /* Ensure the table has a minimum width */
}
