/* libre-baskerville-regular - latin */
@font-face {
  font-family: "Libre Baskerville";
  font-style: normal;
  font-weight: 400;
  src: url("../../../src/assets/fonts/libre/libre-baskerville-v14-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../../src/assets/fonts/libre/libre-baskerville-v14-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../../../src/assets/fonts/libre/libre-baskerville-v14-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../../src/assets/fonts/libre/libre-baskerville-v14-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("../../../src/assets/fonts/libre/libre-baskerville-v14-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../../../src/assets/fonts/libre/libre-baskerville-v14-latin-regular.svg#LibreBaskerville")
      format("svg"); /* Legacy iOS */
}

/* libre-baskerville-italic - latin */
@font-face {
  font-family: "Libre Baskerville";
  font-style: italic;
  font-weight: 400;
  src: url("../../../src/assets/fonts/libre/libre-baskerville-v14-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../../src/assets/fonts/libre/libre-baskerville-v14-latin-italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../../../src/assets/fonts/libre/libre-baskerville-v14-latin-italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../../src/assets/fonts/libre/libre-baskerville-v14-latin-italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("../../../src/assets/fonts/libre/libre-baskerville-v14-latin-italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../../../src/assets/fonts/libre/libre-baskerville-v14-latin-italic.svg#LibreBaskerville")
      format("svg"); /* Legacy iOS */
}

/* libre-baskerville-700 - latin */
@font-face {
  font-family: "Libre Baskerville";
  font-style: normal;
  font-weight: 700;
  src: url("../../../src/assets/fonts/libre/libre-baskerville-v14-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../../src/assets/fonts/libre/libre-baskerville-v14-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../../../src/assets/fonts/libre/libre-baskerville-v14-latin-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../../src/assets/fonts/libre/libre-baskerville-v14-latin-700.woff")
      format("woff"),
    /* Modern Browsers */
      url("../../../src/assets/fonts/libre/libre-baskerville-v14-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../../../src/assets/fonts/libre/libre-baskerville-v14-latin-700.svg#LibreBaskerville")
      format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "Tiempos Headline";
  src: local("Tiempos Headline Regular"), local("Tiempos Headline-Regular");
  src: url("../../assets/fonts/Tiempos-Font/TiemposHeadline-Regular.otf")
    format("opentype");
  font-weight: 400;
  font-style: normal;
}
