@use "../../../styles/abstracts/mixins" as *;
@use "../../../styles/abstracts/variables" as *;

.backstory {
  // height: 70vh;
  position: relative;

  @include respond(phone) {
    padding: 0 1.2rem;
  }

  &__trigger {
    width: 100%;
    height: 46px;
    background-color: $col-primary;
    padding: 0px 24px;
    box-shadow: inset 0px -6px 10px #314f4a;
    @include flex-row;
    @include border-radius(4px);
    @include transition;

    & span {
      color: $col-white !important;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.375rem;
    }

    &:focus {
      outline: 1px dotted transparent;
      outline-offset: 1px;

      & span {
        color: $col-white;
      }
    }

    &:focus-visible {
      outline: 1px dotted $col-secondary-8;
      outline-offset: 1px;

      & span {
        color: $col-white;
      }
    }

    &:disabled,
    &[disabled] {
      @include border-radius(4px);
      background-color: $col-secondary-8;
      box-shadow: none;
      filter: none;

      &:hover,
      &:focus-within {
        background-color: $col-secondary-8;
        cursor: not-allowed;

        & span {
          color: $col-white;
        }
      }
    }

    &:hover,
    &:focus-within {
      background-color: $col-secondary-4;

      & span {
        color: $col-white;
      }
    }

    &:active {
      background-color: $col-primary;
    }
  }

  &__loading {
    height: 50vh;
    width: 100%;
    @include flex-row;
  }

  &__add-cards {
    width: fit-content;
    &-container {
      margin-top: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  &__empty {
    @include respond(phone) {
      padding: 0 1.2rem;
    }

    &-alert {
      position: absolute;
      // margin-top: 24px;
      top: 0;
      left: 0;
      width: 100%;
      height: 73px;
      &-close {
        @include respond(phone) {
          display: none;
        }
      }
      @include respond(phone) {
        height: auto;
      }
      @include flex-row;
      &-container {
        width: fit-content;
        height: 100%;
        background-color: $col-secondary-32;
        border: 1px dashed $col-secondary-28;
        @include border-radius(2px);
        height: 100%;
        padding: 0px 30px 0px 43px;

        @include respond(phone) {
          padding: 16px;
        }

        & p {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          color: $col-secondary-28;
        }

        @include flex-column;
        @include respond(phone) {
          @include grid-column(2, auto);
        }
      }
    }

    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    align-items: center;
    position: relative;

    &-button {
      width: fit-content;
      &-container {
        width: 100%;
        height: 60%;
        @include flex-row;
      }
    }
    &-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      position: relative;
      align-items: center;

      & > svg {
        margin: 1rem;
        width: 190px;
        height: 300px;
      }

      & h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 1.125rem;
        line-height: s;
        text-align: right;
        color: $col-black;
      }
    }
  }
  &__container {
    padding: 16px 0;
    // @include grid-column(auto-fill, 326px);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    width: 100%;
  }
}
