@use "../../styles/abstracts/variables" as *;
@use "../../styles/abstracts/mixins" as *;

.search {
  width: 100%;
  background-color: transparent;
  height: 40px;
  // padding-left: 24px;
  // @include border-radius(4px);
  position: relative;
  & input {
    width: 100%;
    height: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    @include border-radius(4px);
    border: 1px solid $col-secondary-26;
    background-color: $col-secondary-2;
    padding-left: 60px;
    &::placeholder {
      color: $col-secondary-3;
      font-size: 0.875rem;
      line-height: 1.1875rem;
      font-weight: 600;
    }
  }
  &-icon {
    position: absolute;
    height: 100%;
    width: 50px;
    @include flex-row;
    top: 0;
    left: 0;
    // @include z-index(overlay);
  }
}
