@use "src/styles/abstracts/mixins" as *;
@use "src/styles/abstracts/variables" as *;

.messages {
  height: 70vh;
  position: relative;

  @include respond(phone) {
    padding: 0 1.2rem;
    height: 100%;
  }

  &__trigger {
    width: 100%;
    height: 46px;
    background-color: $col-primary;
    padding: 0px 24px;
    box-shadow: inset 0px -6px 10px #314f4a;
    @include flex-row;
    @include border-radius(4px);
    @include transition;

    & span {
      color: $col-white !important;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.375rem;
    }

    &:focus {
      outline: 1px dotted transparent;
      outline-offset: 1px;

      & span {
        color: $col-white;
      }
    }

    &:focus-visible {
      outline: 1px dotted $col-secondary-8;
      outline-offset: 1px;

      & span {
        color: $col-white;
      }
    }

    &:disabled,
    &[disabled] {
      @include border-radius(4px);
      background-color: $col-secondary-8;
      box-shadow: none;
      filter: none;

      &:hover,
      &:focus-within {
        background-color: $col-secondary-8;
        cursor: not-allowed;

        & span {
          color: $col-white;
        }
      }
    }

    &:hover,
    &:focus-within {
      background-color: $col-secondary-4;

      & span {
        color: $col-white;
      }
    }

    &:active {
      background-color: $col-primary;
    }
  }

  &__loading {
    height: 50vh;
    width: 100%;
    @include flex-row;
  }

  &__table {
    margin-top: 20px;
    @include column;
    overflow-x: auto;

    &-header {
      @include flex-row;
      justify-content: flex-start;
      padding: 16px 40px;
      width: 100%;
      height: 45px;
      align-items: center;
      gap: 0.75rem;

      & h4 {
        font-style: normal;
        text-align: left;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        text-align: left;
        min-width: 180px;
        color: #808080;
      }
    }

    &-main {
      overflow-x: auto;
      width: 100%;
    }
  }
  &__button {
    width: fit-content;
    &-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 40px;
    }
  }
}
