@use "../../styles/abstracts/variables.scss" as *;
@use "../../styles/abstracts/mixins" as *;

.bubble {
  &__main {
    &__img-bubble {
      background: $col-secondary-34;
      border: 0.5px solid $col-secondary-4;
      border-radius: 50%;
      display: grid;
      place-items: center;
      box-sizing: border-box;
      width: 40px;
      height: 40px;
    }

    &__bubble {
      background: $col-secondary-34;
      border: 0.5px solid $col-secondary-4;
      border-radius: 50%;
      box-sizing: border-box;
      width: 40px;
      color: #000;
      height: 40px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      display: grid;
      place-items: center;
      text-transform: uppercase;

      @include transition;

      &:hover {
        background-color: $col-secondary-40;
      }

      &__other {
        background: $col-white;

        @include transition;

        &:hover {
          background-color: $col-secondary-40;
        }
      }

      &-fill {
        fill: $col-white;
        color: $col-white;
      }

      &-content {
        background: $col-white;
        border-radius: 4px;
        padding: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px;

        @include floating-transition;
      }
    }

    &__team-content {
      margin-top: 1rem;
      width: 390px;
      background: $col-white;
      box-shadow: 0px 1px 1px rgba(187, 187, 187, 0.25),
        0px 0px 14px rgba(169, 169, 169, 0.25);
      border-radius: 4px;
      padding: 24px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      @include floating-transition;

      @include respond(phone) {
        width: 100%;
      }

      button {
        border: 1px solid $col-black;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 12px 16px;
        box-shadow: none;
        background: $col-white;

        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 18px;
          color: $col-black;
        }

        &:hover,
        &:active {
          background: $col-primary;

          span {
            color: $col-white;
          }
        }

        &:focus {
          outline: 1px dotted transparent;
        }

        &:focus-visible {
          outline: 1px dotted $col-secondary-8;
        }
      }

      h4 {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 24px;
      }

      &__body {
        margin-bottom: 24px;

        &-group {
          @include flex-row;

          justify-content: space-between;
          gap: 0.75rem;
          margin-bottom: 24px;
          width: 100%;

          button {
            border: 1px solid $col-secondary-35;
            box-sizing: border-box;
            border-radius: 2px;
            padding: 8px 12px;
            max-width: 75px;
            height: auto;

            &:hover {
              background: $col-secondary-8;

              span {
                color: $col-black;
              }
            }

            &:focus {
              background: $col-white;
              outline: 1px dotted transparent;
            }

            &:focus-visible {
              background: $col-white;
              outline: 1px dotted $col-secondary-8;
            }

            span {
              font-weight: 400;
              color: $col-black;
              font-size: 14px;
              line-height: 16px;
            }
          }

          &__details {
            h5 {
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
            }

            p {
              font-weight: 400;
              font-size: 14px;
              line-height: 19px;
              color: $col-secondary-9;
            }
          }
        }
      }
    }
  }

  &__popover {
    &:focus {
      outline: 1px dotted transparent;
    }

    &:focus-visible {
      outline: 1px dotted $col-secondary-8;
    }
  }
}
