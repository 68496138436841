$col-secondary: #f2f2f2;
$col-secondary-2: #fafafa;
$col-secondary-3: #bfbfbf;
$col-secondary-4: #3b5f59;
$col-black: #000;
$col-primary: #34544f;
$col-primary-inverse: #b0ceca;
$col-white: #fff;
$font: "Inter", sans-serif;
$Tiempos: "Tiempos Headline";
$col-secondary-5: #b0ceca;
$col-secondary-6: #629d94;
$col-secondary-7: #e6e6e6;
$col-secondary-8: #cccccc;
$col-secondary-9: #808080;
$col-secondary-10: #1fad3e;
$col-secondary-11: #629e94;
$col-secondary-12: #25213b;
$col-secondary-13: #141414;
$col-fail: #e61919;
$col-secondary-14: #009900;
$col-secondary-15: #e61919;
$col-secondary-16: #25213b;
$col-secondary-17: #e9fbed;
$col-secondary-18: #fce8e8;
$col-secondary-19: #b81414;
$col-secondary-20: #1fad3e;
$col-secondary-21: #1ea63b;
$col-secondary-22: #d4f7dc;
$col-secondary-23: #fff8e6;
$col-secondary-24: #ffb800;
$col-secondary-25: #996e00;
$col-secondary-26: #e5e5e5;
$col-secondary-27: #26d94e;
$col-secondary-28: #4e7e77;
$col-secondary-29: #f9fbfb;
$col-secondary-30: #202223;
$col-secondary-31: #808080;
$col-secondary-32: #eff5f4;
$col-secondary-33: #333333;
$col-secondary-34: #e6efee;
$col-secondary-35: #dadada;
$col-secondary-36: #1c9c38;
$col-secondary-37: #374151;
$col-secondary-38: #969696;
$col-secondary-39: #e5e7eb;
$col-secondary-40: #d6e8e6;
$col-secondary-41: #1a8f33;
$col-secondary-42: #f92d2d;
$col-secondary-43: #e4e4e7;
$col-secondary-44: #e3a400;
$col-secondary-45: #ee7303;
