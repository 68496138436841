@use "../../styles/abstracts/variables" as *;
@use "../../styles/abstracts/mixins" as *;

.deleteModal {
  &__message {
    background-color: $col-white;
    width: 551px;
    padding: 40px;
    height: 250px;

    @include respond(tab) {
      padding: 40px;
    }

    @include respond(phone) {
      height: 100vh;
      height: -moz-available;
      height: -webkit-fill-available;
      height: stretch;
      height: calc(100vh - calc(100vh - 100%));
      width: 100vw;
      width: -moz-available;
      width: -webkit-fill-available;
      width: stretch;
      top: 0rem;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;
    }

    @include flex-row;
    @include border-radius(5px);

    &-title {
      @include grid-row(2, auto);
      grid-row-gap: 1.5rem;
      @include grid-center;
      place-items: flex-start;
      width: 100%;

      & p {
        text-align: left;
        width: 100%;
      }
    }

    &-container {
      // @include grid-row(3, auto);
      width: 100%;
      gap: 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;

      & h4 {
        color: $col-black;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
      }

      & p {
        text-align: left;
        font-weight: 400;
        font-size: 16px;
        width: 100%;
        line-height: 24px;
      }
    }
    &-icon {
      width: 98px;
      height: 98px;
      @include flex-row;
    }
    &-button {
      width: fit-content;
    }
    &-buttons {
      width: 100%;
      @include flex-row;
      justify-content: flex-end;
      align-self: flex-end;
    }
  }
}

.cancel-button {
  width: 97px;
  height: 46px;
  background-color: $col-secondary-2;
  @include border-radius(4px);
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  color: $col-black;
  margin-right: 16px;
}
