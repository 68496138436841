@use "../../styles/abstracts/variables.scss" as *;
@use "../../styles/abstracts/mixins" as *;

.myPlan {
  padding: 0 30px;
  overflow-x: auto;

  @include respond(phone) {
    padding: 0;
  }

  &__container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    padding: 40px 0;
    width: fit-content;

    @include respond(phone) {
      padding: 38px 16px;
    }
  }

  &__deck {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: 450px;

    @include respond(phone) {
      width: 80vw;
      min-width: 280px;
    }
  }

  &__mobile-deck {
    @include respond(phone) {
      flex-direction: column-reverse;
    }
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    color: $col-secondary-13;
  }

  &__length {
    color: $col-secondary-13;
    font-size: 14px;
    padding: 2px 8px;
    border: 1px solid #e4e4e7;
    border-radius: 3px;
  }

  &__box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    padding: 24px;
    background: #f4f4f5;
    border-radius: 4px;
    height: 70vh;
    overflow-y: auto;
    width: 100%;
  }

  &__box-2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    padding: 24px;
    background: #f4f4f5;
    border-radius: 4px;
    height: 60vh;
    overflow-y: auto;
    width: 100%;
  }
  &__box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    padding: 24px;
    background: #f4f4f5;
    border-radius: 4px;
    height: 70vh;
    overflow-y: auto;
    width: 100%;
  }

  &__button {
    width: 100%;
    height: 46px;
    background-color: $col-white;
    padding: 0px 24px;
    border: 1px solid #bfbfbf;
    box-sizing: border-box;
    border-radius: 4px;
    @include flex-row;
    @include border-radius(4px);
    @include transition;

    & span {
      color: $col-black;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.375rem;
    }

    &:focus {
      outline-offset: 1px;
      outline: 1px dotted transparent;
    }

    &:focus-visible {
      outline-offset: 1px;
      outline: 1px dotted $col-secondary-8;
    }

    &:disabled,
    &[disabled] {
      @include border-radius(4px);
      background-color: $col-secondary-8;
      box-shadow: none;
      filter: none;

      &:hover,
      &:focus-within {
        background-color: $col-secondary-8;
        cursor: not-allowed;
      }

      & span {
        color: $col-white;
      }
    }

    &:hover,
    &:focus-within {
      background-color: $col-secondary-2;
    }

    &:active {
      background-color: $col-secondary-2;
    }
  }
}
