@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.Info {
  width: fit-content;
  max-width: 580px;
  height: auto;
  padding: 0.8rem 1.5rem;
  border: 1px dashed $col-secondary-6;
  border-radius: 19px;
  background-color: $col-secondary-32;
  display: flex;
  justify-content: center;
  position: relative;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
  gap: 0.25rem;

  p {
    font-size: 1rem;
    width: 100% !important;
    margin: 0 auto !important;
    color: $col-secondary-28 !important;
  }
}
