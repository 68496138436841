@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.addForeignAcct {
  &_content {
    background-color: $col-white;
    padding: 40px;
    height: auto;
    width: 551px;
    @include column;
    gap: 20px;
    border-radius: 5px;
    max-height: 90vh;
    overflow-y: scroll;

    @include respond(phone) {
      height: 100vh;
      height: -moz-available;
      height: -webkit-fill-available;
      height: calc(100vh - calc(100vh - 100%));
      width: 100vw;
      height: calc(100vh - calc(100vh - 100%));
      width: -moz-available;
      width: -webkit-fill-available;
      @include column;
      gap: 0.75rem;
      @include z-index(fill-page);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: fixed;
    }

    &__optionView {
      height: 50vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 3rem;

      .option {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2.5rem;
        cursor: pointer;

        span {
          font-family: $font;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: $col-black;
          text-align: center;
        }
      }
    }

    &_top {
      @include column;
      gap: 0.75rem;

      & h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        height: 40px;
        margin-bottom: 10px;
      }
    }

    &_form {
      &_container {
        margin-bottom: 2rem;
        @include grid-row(auto-fill, auto);
        grid-row-gap: 18px;

        &__tiny {
          position: relative;
          display: flex;
          justify-content: flex-end;
          &__2 {
            text-align: center;
            margin: 1rem auto;
          }
        }

        &__group {
          @include column;
          gap: 0.5rem;
        }

        &__select {
          @include column;
          & label {
            margin-bottom: 8px;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.375rem;
            text-align: left;
            color: $col-black;
          }
          /* class applies to select element itself, not a wrapper element */
          select {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #202223;
            background-color: #fafafa;
            border: 1px solid #f2f2f2;
            border-radius: 2px;
            @include row;
            font-family: $font;
            width: 100%;
            padding: 0.8rem 1.5rem;
            max-width: 100%; /* useful when width is set to anything other than 100% */
            margin: 0;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
            background-repeat: no-repeat, repeat;
            /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
            background-position: right 0.7em top 50%, 0 0;
            /* icon size, then gradient */
            background-size: 0.65em auto, 100%;
          }
          /* Hide arrow icon in IE browsers */
          &::-ms-expand {
            display: none;
          }
          /* Hover style */
          &:hover {
            border: none;
          }
        }

        &__input {
          width: 100%;
          height: 35px;
        }

        &__searchbar {
          position: sticky;
          top: 0;
          padding: 0.15rem;
        }

        &__banks {
          padding: 0.5rem 0.5rem;
          border-radius: 4px;
          z-index: 0;
          margin: 0.25rem 0.1rem;

          &:hover {
            background-color: $col-secondary-40;
          }

          & option {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &__none {
          @include flex-row;
          margin: 1rem 0.5rem;
        }

        &__select-css {
          border: 1px solid $col-secondary;
          outline: none;
          font-weight: normal;
          font-style: normal;
          font-size: 0.875rem;
          line-height: 1.25rem;
          color: #202223;
          text-transform: capitalize;
          width: 100%;
          height: 100%;
          outline: 0;
          padding: 0.8rem 1.5rem;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          padding-right: 1rem;
          background-color: $col-secondary-2;

          @include border-radius(2px);
          @include flex-space-between;

          &__content {
            background: $col-white;
            box-shadow: 0px 0px 2px rgba(125, 125, 125, 0.2),
              0px 2px 10px rgba(0, 0, 0, 0.1);
            padding: 4px;
            @include floating-transition;

            @include z-index("dropdown-menu");
            background-color: $col-white;
            @include border-radius(4px);
            width: 80vw;
            max-width: 475px;
            white-space: nowrap;
            height: auto;
            margin-top: 10px;

            &__input {
              padding: 0.5rem 1rem;
              background-color: #fafafa;
              border: 1px solid #f2f2f2;
              border-radius: 2px;
              height: 50px;
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
            }

            &__error {
              color: $col-fail;
              font-size: 14px;
            }

            &__item {
              height: 250px;
              overflow-y: scroll;
              background-color: $col-white;
              border-radius: 4px;
              cursor: pointer;
              z-index: 2;
              overflow-x: hidden;
            }
          }
        }
      }
    }
  }
}
