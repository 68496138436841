@use "../../styles/abstracts/variables" as *;
@use "../../styles/abstracts/mixins" as *;

.tab {
  @include row;
  overflow-x: auto;
  // border-bottom: 1px solid $col-secondary-7;
  gap: 0.5rem;

  & button {
    padding: 0px 24px;
    height: 46px;
    color: $col-secondary-31;
    text-transform: capitalize;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.375rem;
    white-space: nowrap;
    position: relative;
    border-bottom: 3px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    @include transition;

    @include respond(phone) {
      padding: 0px 10px;
    }

    &::after {
      height: 32px;
      position: absolute;
      content: "";
      background-color: transparent;
      border-radius: 4px;
      width: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      @include transition;
    }

    &:hover {
      color: $col-primary;
      @include transition;

      &::after {
        background-color: $col-secondary-40;
      }
    }

    &:focus {
      color: $col-primary;
      @include transition;

      &::after {
        background-color: $col-secondary-7;
      }
    }

    &[data-active="true"] {
      border-bottom: 3px solid $col-secondary-4;
      color: $col-primary;
      font-weight: 600;
      @include transition;

      &::after {
        background-color: $col-secondary-40;
      }
    }
    &[data-hide="true"] {
      @include respond(phone) {
        display: none;
      }
    }
  }

  &__count {
    font-size: 10px;
    background: $col-primary;
    color: $col-white;
    border-radius: 50%;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
  }
}
