@use "../../styles/abstracts/variables" as *;
@use "../../styles/abstracts/mixins" as *;

.modal {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  // backdrop-filter: blur(8px);
  // -webkit-backdrop-filter: blur(8px);
  position: fixed;
  width: 100%;
  @include z-index(modal);
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  padding-top: 1rem;

  &[aria-modal="true"] {
    display: flex;
  }

  @include respond(phone) {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  [aria-label="close modal"] {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: flex-end;
    align-self: flex-end;
    height: 40px;
    width: 40px;
    border: 1px solid transparent;
    border-radius: 50%;
    background-color: $col-white;

    @include respond(phone) {
      position: absolute;
      z-index: 1001;
      top: 2.4rem;
      right: 0.75rem;
    }
  }

  &-overlay,
  &-content {
    height: 100%;
  }

  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    gap: 20px;
  }

  @include respond(phone) {
    padding: 1rem;
  }
}
