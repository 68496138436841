@use "../../styles/abstracts/mixins" as *;
@use "../../styles/abstracts/variables" as *;

.website {
  height: 100%;
  padding: 0 30px;

  @include respond(phone) {
    padding: 0;
  }

  &__tab-container {
    border-bottom: 1px solid $col-secondary-7;
    width: 100%;
    padding: 1.2rem 1.2rem 0;
    @include row;
    align-items: center;
  }
  &__main-container {
    height: 100%;
    margin-top: 2rem;
  }
  &__carousel {
    color: $col-white;
    display: flex;
    flex-direction: row;
    &-left-arrow {
      margin-right: 38px;
      @include flex-row;
      @include respond(phone) {
        display: none;
      }
    }
    &-right-arrow {
      margin-left: 38px;
      @include flex-row;
      @include respond(phone) {
        display: none;
      }
    }

    @include respond(phone) {
      width: 100%;
      height: 100%;
      position: fixed;
      @include z-index(fill-page);
      top: 0;
      left: 0;
    }

    &-back {
      display: none;
      @include respond(phone) {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 140;
        display: block;
        margin-top: 30px;
        margin-left: 30px;
        & svg path {
          stroke: $col-white !important;
        }
      }
    }

    &-image {
      width: 591px;
      height: 591px;
      position: relative;
      @include respond(phone) {
        width: 100%;
        height: 100%;
      }
      & img {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
